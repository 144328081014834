import React from 'react';
import NavbarComponent from '../common/NavbarComponent/index.jsx';
import FooterComponent from '../common/FooterComponent/Index.jsx';
import './Layout.css'; 

const Layout = ({ children, showNavbar, showFooter }) => {
  return (
    <div>
      {showNavbar && <NavbarComponent />}
      <main className="main-content">{children}</main>
      {showFooter && <FooterComponent />}
    </div>
  );
};

export default Layout
