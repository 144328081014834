import React, { useEffect, useState } from "react";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "./Tabs.css";
import { fetchReviewsByProductId } from '../../../Services/reviewServices';

const DynamicTabs = ({ product, productId }) => {
  const keyFeatures = JSON.parse(product.KeyFeatures || "[]");
  const keyPointsArray = product.KeyPoints
    ? product.KeyPoints.split(/[,\.]/).map(point => point.trim()).filter(point => point)
    : [];

  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isMobile, setIsMobile] = useState(false); // State to track mobile view
  const [activePanel, setActivePanel] = useState(null); // Manage active accordion panel

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const reviewsData = await fetchReviewsByProductId(productId);
        setReviews(reviewsData);
      } catch (error) {
        setError('Error fetching reviews');
      } finally {
        setLoading(false);
      }
    };

    fetchReviews();
  }, [productId]);

  // Function to handle window resize and switch to accordion view on mobile
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize(); // Check initially
    window.addEventListener("resize", handleResize);

    // Cleanup listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Function to toggle the accordion panel
  const togglePanel = (index) => {
    setActivePanel(activePanel === index ? null : index);
  };

  return (
    <div className="tabs-container">
      {isMobile ? (
        // Accordion structure for mobile screens
        <div className="accordion">
          <div className="accordion-item">
            <button onClick={() => togglePanel(0)} className="accordion-header">
              Description
              <span className={`accordion-arrow ${activePanel === 0 ? 'open' : ''}`}><i class="fa-solid fa-chevron-down"></i></span>
            </button>
            <div className={`accordion-body ${activePanel === 0 ? 'active' : ''}`}>
              {keyPointsArray.length > 0 ? (
                <ul>
                  {keyPointsArray.map((point, index) => (
                    <li key={index}>{point}</li>
                  ))}
                </ul>
              ) : (
                <p>No key points available.</p>
              )}
            </div>
          </div>

          <div className="accordion-item">
            <button onClick={() => togglePanel(1)} className="accordion-header">
              Specifications
              <span className={`accordion-arrow ${activePanel === 1 ? 'open' : ''}`}><i class="fa-solid fa-chevron-down"></i></span>
            </button>
            <div className={`accordion-body ${activePanel === 1 ? 'active' : ''}`}>
              {keyFeatures.length > 0 ? keyFeatures.map((feature, index) => (
                <div key={index}>
                  <h4>{feature.feature}</h4>
                  <ul>
                    {feature.points.map((point, i) => <li key={i}>{point}</li>)}
                  </ul>
                </div>
              )) : <p>No key features available.</p>}
            </div>
          </div>

          <div className="accordion-item">
            <button onClick={() => togglePanel(2)} className="accordion-header">
              Ratings and Reviews
              <span className={`accordion-arrow ${activePanel === 2 ? 'open' : ''}`}><i class="fa-solid fa-chevron-down"></i></span>
            </button>
            <div className={`accordion-body ${activePanel === 2 ? 'active' : ''}`}>
              {loading ? (
                <p>Loading reviews...</p>
              ) : error ? (
                <p>{error}</p>
              ) : reviews.length > 0 ? (
                <ul>
                  {reviews.map((review) => (
                    <li key={review.ReviewId}>
                      <strong>Review by User {review.UserId}:</strong> {review.ReviewText}
                      <br />
                      <em>Rating: {review.Rating}</em>
                      <br />
                      <small>Reviewed on: {new Date(review.DateCreated).toLocaleDateString()}</small>
                    </li>
                  ))}
                </ul>
              ) : (
                <p>No reviews available.</p>
              )}
            </div>
          </div>
        </div>
      ) : (
        // Tabs structure for larger screens
        <Tabs>
          <TabList>
            <Tab>Description</Tab>
            <Tab>Specifications</Tab>
            <Tab>Ratings and Reviews</Tab>
          </TabList>

          <TabPanel>
            {keyPointsArray.length > 0 ? (
              <ul>
                {keyPointsArray.map((point, index) => (
                  <li key={index}>{point}</li>
                ))}
              </ul>
            ) : (
              <p>No key points available.</p>
            )}
          </TabPanel>

          <TabPanel>
            {keyFeatures.length > 0 ? keyFeatures.map((feature, index) => (
              <div key={index}>
                <h4>{feature.feature}</h4>
                <ul>
                  {feature.points.map((point, i) => <li key={i}>{point}</li>)}
                </ul>
              </div>
            )) : <p>No key features available.</p>}
          </TabPanel>

          <TabPanel>
            {loading ? (
              <p>Loading reviews...</p>
            ) : error ? (
              <p>{error}</p>
            ) : reviews.length > 0 ? (
              <ul>
                {reviews.map((review) => (
                  <li key={review.ReviewId}>
                    <strong>Review by User {review.UserId}:</strong> {review.ReviewText}
                    <br />
                    <em>Rating: {review.Rating}</em>
                    <br />
                    <small>Reviewed on: {new Date(review.DateCreated).toLocaleDateString()}</small>
                  </li>
                ))}
              </ul>
            ) : (
              <p>No reviews available.</p>
            )}
          </TabPanel>
        </Tabs>
      )}
    </div>
  );
};

export default DynamicTabs;
