import React, { useState, useEffect } from 'react';
import ProductCard from '../../components/common/ProductCardComponent/Index';
import Header from '../../assests/Header.png';
import FilterSidebar from '../../components/FilterSidebar/index';
import ReactPaginate from 'react-paginate';
import { fetch_Products } from '../../Services/ProductServices';
import Spinner from '../../components/common/loadingSpinnercomponent/Spinner';
import './ProductsList.css';

const ProductList = () => {
  const [allProducts, setAllProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const itemsPerPage = 12;

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const productsData = await fetch_Products();
        setAllProducts(productsData);
        setFilteredProducts(productsData);
      } catch (error) {
        console.error('Error fetching products:', error);
        setError('Failed to fetch products. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Smooth scroll to top on page change
  };

  const getPagedData = () => {
    const offset = currentPage * itemsPerPage;
    return filteredProducts.slice(offset, offset + itemsPerPage);
  };

  const currentItems = getPagedData();

  // Calculate displayed items
  const startItem = currentPage * itemsPerPage + 1;
  const endItem = Math.min((currentPage + 1) * itemsPerPage, filteredProducts.length);

  return (
    <div>
      {isLoading && (
        <div className="loading-container">
          <Spinner />
        </div>
      )}
      {error && (
        <div className="error-container">
          Error: {error}
        </div>
      )}
      {!isLoading && !error && (
        <>
          <header className="header-image-container">
            <img src={Header} alt="Header" className="img-fluid" />
          </header>
          <div className="row mt-4 mx-0">
            <h1 className="h4 heading">All Products</h1>
            <div className="col text-right">
              <span className="items-count">
                Showing  {endItem} of {filteredProducts.length} results
              </span>
              {/* Dropdown for sorting */}
              <div className="dropdown-container">
                <select className="custom-dropdown">
                  <option value="latest">Sort By Latest</option>
                  <option value="price-low">Price: Low to High</option>
                  <option value="price-high">Price: High to Low</option>
                  <option value="rating">Sort By Rating</option>
                </select>
              <i class="fa-solid fa-chevron-down dropdown-icon"></i>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3">
                <FilterSidebar
                  allProducts={allProducts}
                  setFilteredProducts={setFilteredProducts}
                />
              </div>
              <div className="col-lg-9">
                <div className="row">
                  {currentItems.length === 0 ? (
                    <div className="no-products">
                      No products found. Please adjust your filters.
                    </div>
                  ) : (
                    currentItems.map(product => (
                      <div className="col-lg-4 col-md-6 mb-4" key={product.ProductId}>
                        <ProductCard product={product} />
                      </div>
                    ))
                  )}
                </div>
                {/* Render pagination if at least one item is present */}
                {filteredProducts.length > 0 && (
                  <div className="pagination-container">
                    <ReactPaginate
                      previousLabel=<i class="fa-solid fa-chevron-left"></i>
                      nextLabel=<i class="fa-solid fa-chevron-right"></i>
                      breakLabel="..."
                      pageCount={Math.ceil(filteredProducts.length / itemsPerPage)}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageClick}
                      containerClassName="pagination"
                      activeClassName="active"
                      previousClassName="page-item"
                      previousLinkClassName="page-link"
                      nextClassName="page-item"
                      nextLinkClassName="page-link"
                      pageClassName="page-item"
                      pageLinkClassName="page-link"
                      breakClassName="page-item"
                      breakLinkClassName="page-link"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ProductList;
