import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick"; // Import the Slider component from react-slick
import "./Home.css"; // Make sure your styles are imported
import HorizontalCarousel from "../../components/common/productcarouselComponent/Index.jsx";
import ProductBanner from "../../components/Home/ProductShowcase/ProductShowcase.jsx";
import ReviewCard from "../../components/Home/Testimonials/Index.jsx";
import BannerComponent from "../../components/common/BannerComponent/Index.jsx";
import { fetchProducts } from "../../slices/ProductSlice";
import ProductViewModal from "../../components/common/modalComponent/productModal/index.js";
import Spinner from "../../components/common/loadingSpinnercomponent/Spinner.jsx";


import HeaderImage1 from "../../assests/product.png";
import HeaderImage2 from "../../assests/ProductCard/Banner Image.png";
import HeaderImage3 from "../../assests/ProductCard/Banner Image.png";
import HeaderImage4 from "../../assests/Hero 1 (1).jpg";
import HeaderImage5 from "../../assests/Hero 1 (2).jpg";
import HeaderImage6 from "../../assests/Hero 1 (3).jpg";

function Home() {
  const dispatch = useDispatch();
  const { products, loading, error } = useSelector((state) => state.product);
  const [showCookieMessage, setShowCookieMessage] = useState(true);

  const reviews = [
    {
      name: "Darshika C",
      time: "A month ago",
      text: "The dresses at Jewelbox are very good quality, from the material to the color. With exceptional designs as well. Thank you Anusha for arranging to send on time.",
      avatar: "https://via.placeholder.com/50",
    },
    // other reviews
  ];

  // Fetch products when component mounts
  useEffect(() => {
    dispatch(fetchProducts());
  }, [dispatch]);

  const handleDismissCookieMessage = () => {
    setShowCookieMessage(false);
    // You might want to set a cookie or local storage item here to remember the user's preference
  };

  // Slider settings for react-slick
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false, // Optional: set to true if you want navigation arrows
  };

  return (
    <>
      {loading ? (
        <Spinner />
      ) : error ? (
        <div className="alert alert-danger" role="alert">
          {error}
        </div>
      ) : (
        <>
          {/* Sliding Header Section */}
          <Slider {...sliderSettings}>
            <div>
              <img src={HeaderImage2} alt="Slide 2" className="img-fluid Header" />
            </div>
            <div>
              <img src={HeaderImage3} alt="Slide 3" className="img-fluid Header" />
            </div>
            <div>
              <img src={HeaderImage4} alt="Slide 4" className="img-fluid Header" />
            </div>
            <div>
              <img src={HeaderImage5} alt="Slide 5" className="img-fluid Header" />
            </div>
            <div>
              <img src={HeaderImage6} alt="Slide 6" className="img-fluid Header" />
            </div>
          </Slider>

          {/* Main Content Section */}
          {showCookieMessage && (
            <div className="cookie-message">
              <div className="cookie-content">
                <i className="fa-solid fa-cookie-bite"></i>
                <span>
                  We use cookies on this site to analyze traffic, remember your
                  preferences, and optimize your experience.
                </span>
                <a href="#" className="learn-more">
                  Learn more
                </a>
              </div>
              <button className="got-it" onClick={handleDismissCookieMessage}>
                Got it
              </button>
            </div>
          )}
          <div className="container">
            <h1 className="text-start">New Arrivals</h1>
            <HorizontalCarousel products={products} />

            <ProductBanner />

            <h1 className="text-start">Best Selling</h1>
            <HorizontalCarousel products={products} />

            <h1 className="testimonial-title">Testimonials</h1>
            <div className="testimonial">
              <div className="row">
                {reviews.length > 0 ? (
                  reviews.map((review, index) => (
                    <div className="col-md-12" key={index}>
                      <ReviewCard review={review} />
                    </div>
                  ))
                ) : (
                  <p>No testimonials available at the moment.</p>
                )}
              </div>
            </div>
          </div>

          {/* Banner and Product Modal */}
          <BannerComponent />
          <ProductViewModal />
        </>
      )}
    </>
  );
}

export default Home;
