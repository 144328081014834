import React, { useState, useEffect } from "react";
import ProductCard from "../../components/common/ProductCardComponent/Index";
import Header from "../../assests/Header.png";
import FilterSidebar from "../../components/FilterSidebar/index";
import ReactPaginate from 'react-paginate';
import { fetchProductsByCollection } from '../../Services/CategoryServices';
import { useParams, useLocation } from 'react-router-dom';
import './Collections.css'; // Ensure to create this CSS file or include styles in a global CSS

const Collection = () => {
    const [productData, setProductData] = useState([]);
    const [collectionName, setCollectionName] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const itemsPerPage = 12; // 3 rows x 4 columns
    const { slug } = useParams();
    const location = useLocation();

    useEffect(() => {
        const getProducts = async () => {
            setIsLoading(true);
            try {
                console.log(`Fetching products for collection: ${slug}`);
                const fetchedProducts = await fetchProductsByCollection(slug);
                console.log('Fetched products:', fetchedProducts);
                setProductData(fetchedProducts);

                // Check if location.state has the collection data
                if (location.state?.collection?.Collection) {
                    console.log('Collection data:', location.state.collection);
                    setCollectionName(location.state.collection.Collection);
                }
            } catch (error) {
                console.error('Failed to fetch products:', error);
            } finally {
                setIsLoading(false);
            }
        };

        getProducts();
    }, [slug, location.state]);

    const handlePageClick = ({ selected }) => {
        console.log(`Page clicked: ${selected}`);
        setCurrentPage(selected);
    };

    const getPagedData = () => {
        const offset = currentPage * itemsPerPage;
        const currentItems = productData.slice(offset, offset + itemsPerPage);
        console.log('Current items for page:', currentItems);
        return currentItems;
    };

    const currentItems = getPagedData();

    return (
        <div>
            <header className="header-image-container">
                <img src={Header} alt="Header" className="img-fluid" />
            </header>
            <div className="row mt-4 mx-0">
                <h1 className="h4 heading">{collectionName || "Collections"}</h1>
            </div>
            <div className="row mt-4 mx-0">
                <div className="col-md-2">
                    <FilterSidebar />
                </div>
                <div className="col-md-10">
                    {isLoading ? (
                        <div>Loading...</div>
                    ) : (
                        <>
                            {productData.length === 0 ? (
                                <div>No products found for this collection.</div>
                            ) : (
                                <div className="category-card-container">
                                    {currentItems.map((product, index) => (
                                        <ProductCard key={index} product={product} />
                                    ))}
                                </div>
                            )}
                            <ReactPaginate
                                previousLabel=<i class="fa-solid fa-chevron-left"></i>
                                nextLabel=<i class="fa-solid fa-chevron-right"></i>
                                breakLabel={"..."}
                                pageCount={Math.ceil(productData.length / itemsPerPage)}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={3}
                                onPageChange={handlePageClick}
                                containerClassName={"pagination"}
                                activeClassName={"active"}
                                disabledClassName={productData.length === 0 ? "disabled" : ""}
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                previousClassName={"page-item"}
                                previousLinkClassName={"page-link"}
                                nextClassName={"page-item"}
                                nextLinkClassName={"page-link"}
                                breakClassName={"page-item"}
                                breakLinkClassName={"page-link"}
                            />
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Collection;
