import axios from 'axios';
import { API_BASE_URL } from '../config/config';

export const fetchAllTestimonials = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/user/products/testimonials/all/`);
    console.log('Fetched testimonials response:', response.data); // Log the entire response for debugging
    return response.data.testimonials;
  } catch (error) {
    console.error('Error fetching testimonials:', error);
    throw error;
  }
};
