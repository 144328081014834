import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  items: JSON.parse(localStorage.getItem('cartItems')) || [],
  checkoutData: null,
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addToCart: (state, action) => {
      const existingItem = state.items.find(
        item => item.ProductId === action.payload.ProductId && item.Color === action.payload.Color && item.Size === action.payload.Size
      );
      let newItems;
      if (existingItem) {
        newItems = state.items.map(item =>
          item.ProductId === action.payload.ProductId && item.Color === action.payload.Color && item.Size === action.payload.Size
            ? { ...item, quantity: item.quantity + action.payload.quantity }
            : item
        );
      } else {
        newItems = [...state.items, action.payload];
      }
      localStorage.setItem('cartItems', JSON.stringify(newItems));
      return {
        ...state,
        items: newItems,
      };
    },
    updateQuantity: (state, action) => {
      const { ProductId, Color, quantity } = action.payload;
      const newItems = state.items.map(item =>
        item.ProductId === ProductId && item.Color === Color
          ? { ...item, quantity }
          : item
      );
      localStorage.setItem('cartItems', JSON.stringify(newItems));
      return { ...state, items: newItems };
    },
    removeItem: (state, action) => {
      const { ProductId, Color } = action.payload;
      const newItems = state.items.filter(item =>
        !(item.ProductId === ProductId && item.Color === Color)
      );
      localStorage.setItem('cartItems', JSON.stringify(newItems));
      return { ...state, items: newItems };
    },
    setCheckoutData: (state, action) => {
      state.checkoutData = action.payload;
      state.items = []; // Clear cart items
    },
    clearCart: (state) => {
      state.items = [];
      localStorage.removeItem('cartItems'); // Clear cart items from localStorage
    }
  },
});

export const { addToCart, updateQuantity, removeItem, setCheckoutData, clearCart } = cartSlice.actions;
export default cartSlice.reducer;
