import React, { useState, useEffect } from "react";
import ProductCard from "../../components/common/ProductCardComponent/Index";
import Header from "../../assests/Header.png";
import FilterSidebar from "../../components/FilterSidebar/index";
import ReactPaginate from 'react-paginate';
import { fetchProductsByCategory, fetchProductsBySubCategory } from '../../Services/CategoryServices';
import { useParams } from 'react-router-dom';
import './Categories.css'; // Ensure you have the CSS file

const CategoryList = () => {
    const [productData, setProductData] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const itemsPerPage = 12; // Number of items per page
    const { slug } = useParams();

    useEffect(() => {
        const getProducts = async () => {
            setIsLoading(true);
            try {
                console.log(`Fetching products for category or subcategory: ${slug}`);
                let fetchedProducts = await fetchProductsByCategory(slug);

                if (!fetchedProducts || fetchedProducts.length === 0) {
                    // If no products found, attempt to fetch as a subcategory
                    fetchedProducts = await fetchProductsBySubCategory(slug);
                }

                console.log('Fetched products:', fetchedProducts);
                setProductData(fetchedProducts || []);
            } catch (error) {
                console.error('Failed to fetch products:', error);
            } finally {
                setIsLoading(false);
            }
        };

        getProducts();
    }, [slug]);

    const handlePageClick = ({ selected }) => {
        console.log(`Page clicked: ${selected}`);
        setCurrentPage(selected);
    };

    const getPagedData = () => {
        const offset = currentPage * itemsPerPage;
        const currentItems = productData.slice(offset, offset + itemsPerPage);
        console.log('Current items for page:', currentItems);
        return currentItems;
    };

    const currentItems = getPagedData();

    return (
        <div>
            <header className="header-image-container">
                <img src={Header} alt="Header" className="img-fluid" />
            </header>
            <div className="row mt-4 mx-0">
                <h1 className="h4 heading">All Products</h1>
            </div>
            <div className="row mt-4 mx-0">
                <div className="col-md-2">
                    <FilterSidebar />
                </div>
                <div className="col-md-10">
                    {isLoading ? (
                        <div>Loading...</div>
                    ) : (
                        <>
                            {productData.length === 0 ? (
                                <div>No products found for this category.</div>
                            ) : (
                                <div className="category-card-container">
                                    {currentItems.map((product, index) => (
                                        <ProductCard key={index} product={product} />
                                    ))}
                                </div>
                            )}
                            {/* Only show pagination if there are products */}
                            {productData.length > itemsPerPage && (
                                <ReactPaginate
                                    previousLabel={"<"}
                                    nextLabel={">"}
                                    breakLabel={"..."}
                                    pageCount={Math.ceil(productData.length / itemsPerPage)}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={3}
                                    onPageChange={handlePageClick}
                                    containerClassName={"pagination"}
                                    activeClassName={"active"}
                                    disabledClassName={productData.length === 0 ? "disabled" : ""}
                                    pageClassName={"page-item"}
                                    pageLinkClassName={"page-link"}
                                    previousClassName={"page-item"}
                                    previousLinkClassName={"page-link"}
                                    nextClassName={"page-item"}
                                    nextLinkClassName={"page-link"}
                                    breakClassName={"page-item"}
                                    breakLinkClassName={"page-link"}
                                />
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default CategoryList;
