// src/slices/wishlistSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  wishlistProductIds: JSON.parse(localStorage.getItem('wishlistProductIds')) || [],
};

const wishlistSlice = createSlice({
  name: 'wishlist',
  initialState,
  reducers: {
    toggleWishlistProduct: (state, action) => {
      const productId = action.payload;
      if (state.wishlistProductIds.includes(productId)) {
        state.wishlistProductIds = state.wishlistProductIds.filter(id => id !== productId);
      } else {
        state.wishlistProductIds.push(productId);
      }
      localStorage.setItem('wishlistProductIds', JSON.stringify(state.wishlistProductIds));
    },
  },
});

export const { toggleWishlistProduct } = wishlistSlice.actions;

export default wishlistSlice.reducer;
