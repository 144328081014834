import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchProductBySlug, fetch_Products } from '../Services/ProductServices';

// Async thunk to fetch all products
export const fetchProducts = createAsyncThunk(
  'product/fetchProducts',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetch_Products();
      return response; // Assuming fetchProductData() returns the correct data directly
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Async thunk to fetch product details by slug
export const fetchProductDetails = createAsyncThunk(
  'product/fetchProductDetails',
  async (slug, { rejectWithValue }) => {
    try {
      const response = await fetchProductBySlug(slug);
      return response; // Assuming fetchProductBySlug() returns the correct data directly
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  products: [],
  isModalOpen: false,
  selectedProduct: null,
  loading: false,
  error: null,
};

const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    setModalOpen: (state, action) => {
      state.isModalOpen = action.payload;
    },
    setSelectedProduct: (state, action) => {
      state.selectedProduct = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProducts.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.loading = false;
        state.products = action.payload;
      })
      .addCase(fetchProducts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchProductDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchProductDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.selectedProduct = action.payload;
      })
      .addCase(fetchProductDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { setModalOpen, setSelectedProduct } = productSlice.actions;
export default productSlice.reducer;
