import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setModalOpen } from '../../../../slices/ProductSlice';
import { useNavigate } from 'react-router-dom';
import Mintpay from '../../../../assests/ProductCard/Mintpay.png';
import Koko from '../../../../assests/ProductCard/Koko.png';
import './OrderModal.css';
import shoppingBagImage from '../../../../assests/shopping-bag 1.png';
import { Image_URL } from '../../../../config/config';
import { addToCart } from '../../../../slices/CartSlice';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const ProductViewModal = () => {
  const dispatch = useDispatch();
  const isModalOpen = useSelector((state) => state.product.isModalOpen);
  const product = useSelector((state) => state.product.selectedProduct);
  const [selectedSize, setSelectedSize] = useState('');
  const [quantity, setQuantity] = useState(1);
  const [showSizeChart, setShowSizeChart] = useState(false);
  const [selectedColor, setSelectedColor] = useState('');
  const [mainImage, setMainImage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (product && product.Colors.length > 0) {
      const initialColor = product.Colors[0];
      handleColorClick(initialColor);
    }
  }, [product]);

  const handleSizeSelect = (size) => {
    setSelectedSize(size);
    const availableColors = product.Colors.filter(color => color.Size === size);
    const colorMatch = availableColors.find(color => color.Color === selectedColor);
    if (colorMatch) {
      handleColorClick(colorMatch);
    } else if (availableColors.length > 0) {
      handleColorClick(availableColors[0]);
    }
  };

  const handleQuantityChange = (event) => {
    setQuantity(parseInt(event.target.value, 10));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!selectedSize) {
      toast.error('Please select a size');
      return;
    }
    const productToAdd = {
      ProductId: product.ProductId,
      Title: product.Title,
      Price: product.Price,
      Color: selectedColor,
      Size: selectedSize,
      quantity: quantity,
      Image: mainImage
    };
    dispatch(addToCart(productToAdd));
    dispatch(setModalOpen(false)); // Close the modal after adding the product to the cart
  };

  const handleSizeChartClick = () => {
    setShowSizeChart(!showSizeChart);
  };

  const handleColorClick = (color) => {
    const firstImage = color.Sources.split(',')[0].trim();
    setSelectedColor(color.Color);
    setMainImage(firstImage);
  };

  const getUniqueSizes = (colors) => {
    const uniqueSizes = new Set();
    colors.forEach((color) => {
      uniqueSizes.add(color.Size);
    });
    return Array.from(uniqueSizes);
  };

  const getUniqueColors = () => {
    const uniqueColors = new Set();
    return product.Colors.filter(color => {
      if (!uniqueColors.has(color.Color)) {
        uniqueColors.add(color.Color);
        return true;
      }
      return false;
    });
  };

  const handleViewDetailsClick = () => {
    dispatch(setModalOpen(false)); // Close the modal before navigating
    navigate(`/product/${product.Slug}`); // Navigate to the product page
  };

  const handleClose = () => {
    dispatch(setModalOpen(false));
  };

  if (!isModalOpen || !product) {
    return null;
  }

  return (
    <>
      <div className={`modal fade ${isModalOpen ? 'show' : ''}`} tabIndex="-1" style={{ display: isModalOpen ? 'block' : 'none' }} role="dialog">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="btn-close" aria-label="Close" onClick={handleClose}></button>
            </div>
            <form className="modal-product-view" onSubmit={handleSubmit}>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-6">
                    <div className="modal-product-image">
                      <img
                        src={Image_URL + 'products/thumbnails/' + mainImage}
                        alt={product.Title}
                        onError={(e) => {
                          e.target.src = 'https://jbadmin.alephaz.com/assets/img/placeholder-image.jpg';
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="modal-product-details">
                      <h1>{product.Title}</h1>
                      <p className="modal-price">Rs {product.Price}</p>
                      <p className="modal-installment">
                        or 3 installments of Rs {Math.floor(product.Price / 3)} with
                      </p>
                      <div className="modal-payment-logos-container">
                        <img src={Mintpay} alt="MintPay" className="modal-payment-logo me-2" />
                        <p className="modal-installment">or</p>
                        <img src={Koko} alt="Koko" className="modal-payment-logo" />
                      </div>
                      <p className="modal-description">{product.Description}</p>
                      <p className="modal-view-details" onClick={handleViewDetailsClick}>View Details</p>
                      <p className="modal-size-label">Size:</p>
                      <div className="modal-size-options">
                        {getUniqueSizes(product.Colors).map((size) => (
                          <button
                            type="button"
                            key={size}
                            onClick={() => handleSizeSelect(size)}
                            className={`modal-size-button ${selectedSize === size ? 'selected' : ''}`}
                          >
                            {size}
                          </button>
                        ))}
                      </div>
                      <p className="modal-view-size-chart" onClick={handleSizeChartClick}>
                        View Size Chart
                      </p>
                      {showSizeChart && (
                        <div className="modal-size-chart">
                          <img
                            src="https://www.glamly.com/cdn/shop/articles/MicrosoftTeams-image_46_1200x1200.png?v=1698044645"
                            alt="Size Chart"
                            className="modal-size-chart-image"
                          />
                        </div>
                      )}
                      <div className="modal-color-dots-container">
                        {getUniqueColors().map((color, index) => (
                          <div
                            key={index}
                            className={`modal-color-dot ${selectedColor === color.Color ? 'selected' : ''}`}
                            style={{ backgroundColor: color.Color }}
                            title={color.Color}
                            onClick={() => handleColorClick(color)}
                          ></div>
                        ))}
                      </div>
                      <div className="modal-quantity-cart">
                        <input
                          type="number"
                          min="1"
                          value={quantity}
                          onChange={handleQuantityChange}
                        />
                        <button type="submit" className="modal-add-to-cart">
                          <img src={shoppingBagImage} alt="shopping bag" className="modal-shopping-bag-icon" /> Add to Cart
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default ProductViewModal;
