import React from "react";
import { useDispatch, useSelector } from "react-redux";
import "./Login.css";
import loginImage from "../../../assests/auth pages.png";
import googleLogo from "../../../assests/google-logo.png";
import { validateEmail, validatePassword } from "../../common/Validation";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LOGIN_URL } from "../../../config/config";
import {
  setEmail,
  setPassword,
  setEmailError,
  setPasswordError,
  setLoginError,
  setLoginSuccess,
  setTokens,
} from "../../../slices/authSlice";

const Login = () => {
  const dispatch = useDispatch();
  const { email, password, emailError, passwordError, loginError, loginSuccess } = useSelector((state) => state.auth);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const emailValidationError = validateEmail(email);
    const passwordValidationError = validatePassword(password);

    dispatch(setEmailError(emailValidationError));
    dispatch(setPasswordError(passwordValidationError));

    if (!emailValidationError && !passwordValidationError) {
      try {
        const response = await fetch(LOGIN_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email, password }),
        });

        const data = await response.json();

        if (response.ok && data.status === 1) {
          localStorage.setItem("tokenData", JSON.stringify({
            accessToken: data.accessToken,
            refreshToken: data.refreshToken,
          }));

          dispatch(setTokens({
            accessToken: data.accessToken,
            refreshToken: data.refreshToken,
          }));
          dispatch(setLoginSuccess("Login successful!"));
          dispatch(setLoginError(""));
          toast.success("Login successful!");
        } else {
          dispatch(setLoginError(data.message || "Login failed. Please try again."));
          dispatch(setLoginSuccess(""));
          toast.error(data.message || "Login failed. Please try again.");
        }
      } catch (error) {
        console.error("Error during login:", error);
        dispatch(setLoginError("Something went wrong. Please try again."));
        dispatch(setLoginSuccess(""));
        toast.error("Something went wrong. Please try again.");
      }
    }
  };

  const handleEmailChange = (e) => {
    dispatch(setEmail(e.target.value));
  };

  const handlePasswordChange = (e) => {
    dispatch(setPassword(e.target.value));
  };

  return (
    <div className="login-page">
      <div className="login-grid">
        <div className="login-image">
          <img src={loginImage} alt="Login Visual" />
        </div>
        <div className="login-form-container">
          <div className="login-form">
            <form onSubmit={handleSubmit}>
              <div className="styleName">Hello Again</div>
              <p>Welcome back! Please enter your details.</p>
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                placeholder="Enter your email"
                value={email}
                onChange={handleEmailChange}
                maxLength="100"
                required
              />
              {emailError && <div className="error-message">{emailError}</div>}
              <label htmlFor="password">Password</label>
              <input
                type="password"
                id="password"
                placeholder="Enter your password"
                value={password}
                onChange={handlePasswordChange}
                maxLength="20"
                required
              />
              {passwordError && <div className="error-message">{passwordError}</div>}
              {loginError && <div className="error-message">{loginError}</div>}
              {loginSuccess && <div className="success-message">{loginSuccess}</div>}
              <div className="forgot-password">
                <a href="/forgot-password">Forgot password</a>
              </div>
              <button type="submit" style={{ backgroundColor: "#EDA107" }}>
                Sign in
              </button>
              <button type="button" className="google-signin">
                <img src={googleLogo} alt="Google Logo" /> Sign in with Google
              </button>
              <div className="signup-link">
                Don't have an account? <a href="/signup">Sign up</a>
              </div>
            </form>
          </div>
        </div>
      </div>

      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default Login;