import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./ViewProduct.css";
import shoppingBagImage from "../../assests/shopping-bag 1.png";
import DynamicTabs from "../../components/Viewproduct/Tabs/index.jsx";
import VerticalCarousel from "../../components/Viewproduct/Carousel/Index.jsx";
import Mintpay from "../../assests/ProductCard/Mintpay.png";
import Koko from "../../assests/ProductCard/Koko.png";
import { Image_URL, Related_Products_URL } from "../../config/config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HorizontalCarousel from "../../components/common/productcarouselComponent/Index.jsx";
import { fetchProductBySlug } from "../../Services/ProductServices";
import axios from "axios";
import Spinner from "../../components/common/loadingSpinnercomponent/Spinner.jsx";
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../../slices/CartSlice";
import { toggleWishlistProduct } from "../../slices/wishlistSlice"; // Import the wishlist slice

const ViewProduct = () => {
  const { slug } = useParams();
  const [product, setProduct] = useState(null);
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [selectedSize, setSelectedSize] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [showSizeChart, setShowSizeChart] = useState(false);
  const [selectedColor, setSelectedColor] = useState("");
  const [mainImage, setMainImage] = useState("");
  const [colorImages, setColorImages] = useState([]);
  const dispatch = useDispatch();
  const wishlistProductIds = useSelector(
    (state) => state.wishlist.wishlistProductIds
  );

  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        const productDetails = await fetchProductBySlug(slug);
        setProduct(productDetails[0]);
        if (productDetails[0].Colors.length > 0) {
          const initialColor = productDetails[0].Colors[0];
          handleColorClick(initialColor);
        }
        fetchRelatedProducts(
          productDetails[0].CategoryId,
          productDetails[0].SubCategoryId,
          productDetails[0].CollectionId
        );
      } catch (error) {
        console.error("Error fetching product details:", error);
      }
    };
    fetchProductDetails();
  }, [slug]);

  const fetchRelatedProducts = async (
    categoryId,
    subcategoryId,
    collectionId
  ) => {
    try {
      const response = await axios.get(
        `${Related_Products_URL}?categoryId=${categoryId}&subcategoryId=${subcategoryId}&collectionId=${collectionId}`
      );
      if (response.data.status === 1) {
        setRelatedProducts(response.data.data);
      } else {
        console.error(
          "Error fetching related products:",
          response.data.message
        );
      }
    } catch (error) {
      console.error("Error fetching related products:", error);
    }
  };

  const handleSizeSelect = (size) => {
    if (!product || !product.Colors) return;

    if (size === selectedSize) {
      setSelectedSize("");
      setSelectedColor("");
      setColorImages([]);
    } else {
      setSelectedSize(size);
      const availableColors = product.Colors.filter(
        (color) => color.Size === size
      );
      const colorMatch = availableColors.find(
        (color) => color.Color === selectedColor
      );
      if (colorMatch) {
        handleColorClick(colorMatch);
      } else if (availableColors.length > 0) {
        handleColorClick(availableColors[0]);
      }
    }
  };

  const handleQuantityChange = (event) => {
    setQuantity(parseInt(event.target.value, 10));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!selectedSize) {
      toast.error("Please select a size");
      return;
    }

    const productToAdd = {
      ProductId: product.ProductId,
      Title: product.Title,
      Price: product.Price,
      Color: selectedColor,
      Size: selectedSize,
      quantity: quantity,
      Image: mainImage,
    };

    dispatch(addToCart(productToAdd));
    toast.success("Product added to cart!");
  };

  const handleSizeChartClick = () => {
    setShowSizeChart(!showSizeChart);
  };

  const handleColorClick = (color) => {
    const images = color.Sources.split(",").map((source) => source.trim());
    const firstImage = images[0];
    setSelectedColor(color.Color);
    setMainImage(firstImage);
    setColorImages(images);
  };

  const getUniqueSizes = (colors) => {
    if (!colors || colors.length === 0) {
      return [];
    }
    const uniqueSizes = new Set();
    colors.forEach((color) => {
      uniqueSizes.add(color.Size);
    });
    return Array.from(uniqueSizes);
  };

  const uniqueColorsForSize = (selectedSize) => {
    if (!product || !product.Colors) return [];
    const colorSet = new Set();
    const uniqueColors = product.Colors.filter(
      (color) =>
        (selectedSize ? color.Size === selectedSize : true) &&
        !colorSet.has(color.Color) &&
        colorSet.add(color.Color)
    );
    return uniqueColors;
  };

  const handleCardClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const toggleWishlist = () => {
    dispatch(toggleWishlistProduct(product.ProductId));
  };

  if (!product) {
    return <Spinner />;
  }

  const uniqueSizes = getUniqueSizes(product.Colors);
  const isWishlisted = wishlistProductIds.includes(product.ProductId);

  return (
    <>
      <div className="view-product-container">
        <ToastContainer />
        <form className="view-product-wrapper" onSubmit={handleSubmit}>
          <div className="view-product-image-wrapper">
            <VerticalCarousel
              images={colorImages}
              setMainImage={setMainImage}
            />
            <div className="view-product-main-image">
              {mainImage && (
                <img
                  src={Image_URL + "products/thumbnails/" + mainImage}
                  alt={product.Title}
                  onClick={handleCardClick}
                  onError={(e) => {
                    e.target.src =
                      "https://jbadmin.alephaz.com/assets/img/placeholder-image.jpg";
                  }}
                />
              )}
              <div
                className="view-product-heart-icon-container"
                onClick={toggleWishlist}
              >
                {isWishlisted ? (
                  <i className="fa-solid fa-heart" style={{ color: "red" }}></i> // Filled heart if wishlisted
                ) : (
                  <i className="fa-regular fa-heart"></i> // Empty heart if not wishlisted
                )}
              </div>
            </div>
          </div>
          <div className="view-product-details">
            <h1>{product.Title}</h1>
            <p className="view-product-description">{product.Description}</p>
            <p className="view-product-price">Rs {product.Price}</p>
            <div className="view-product-installment">
              <p>
                or 3 installments of Rs {Math.floor(product.Price / 3)} with
              </p>
              <div className="view-product-payment-logos-container">
                <img
                  src={Mintpay}
                  alt="MintPay"
                  className="view-product-payment-logo me-2"
                />
                <p className="view-product-installments">or</p>
                <img
                  src={Koko}
                  alt="Koko"
                  className="view-product-payment-logo"
                />
              </div>
            </div>
            <p className="view-product-size-label">Size:</p>
            <div className="view-product-size-options">
              {uniqueSizes.map((size) => {
                const sizeOption = product.Colors.find(
                  (color) => color.Size === size
                );
                const isOutOfStock =
                  sizeOption && parseInt(sizeOption.Qty) === 0;
                return (
                  <button
                    type="button"
                    key={size}
                    onClick={() => handleSizeSelect(size)}
                    className={
                      selectedSize === size
                        ? "view-product-selected view-product-size-options button selected"
                        : "view-product-size-options button"
                    }
                    disabled={isOutOfStock}
                    title={isOutOfStock ? "Out of stock" : ""}
                  >
                    {size}
                  </button>
                );
              })}
            </div>

            <p
              className="view-product-size-chart-toggle"
              onClick={handleSizeChartClick}
            >
              {showSizeChart ? "Hide Size Chart" : "View Size Chart"}
            </p>
            {showSizeChart && (
              <div className="view-product-size-chart">
                <img
                  src="https://www.glamly.com/cdn/shop/articles/MicrosoftTeams-image_46_1200x1200.png?v=1698044645"
                  alt="Size Chart"
                  className="view-product-size-chart-image"
                />
              </div>
            )}
            <div className="view-product-color-dots-container">
              {uniqueColorsForSize(selectedSize).map((color, index) => (
                <div
                  key={index}
                  className={`view-product-color-dot ${
                    selectedColor === color.Color ? "view-product-selected" : ""
                  }`}
                  style={{ backgroundColor: color.Color }}
                  title={color.Color}
                  onClick={() => handleColorClick(color)}
                ></div>
              ))}
            </div>
            <div className="view-product-quantity-cart">
              <div className="view-product-quantity-counter">
                <button
                  type="button"
                  className="view-product-quantity-btn-left"
                  onClick={() => setQuantity(quantity > 1 ? quantity - 1 : 1)}
                >
                  <i className="fa-solid fa-minus"></i>
                </button>
                <input
                  type="number"
                  min="1"
                  value={quantity}
                  onChange={handleQuantityChange}
                  className="view-product-quantity-field"
                />
                <button
                  type="button"
                  className="view-product-quantity-btn"
                  onClick={() => setQuantity(quantity + 1)}
                >
                  <i className="fa-solid fa-plus"></i>
                </button>
              </div>
              <button type="submit" className="view-product-add-to-cart">
                <img
                  src={shoppingBagImage}
                  alt="shopping bag"
                  className="view-product-shopping-bag-icon"
                />{" "}
                <p>Add to Cart</p>
              </button>
            </div>
          </div>
        </form>
        {product && (
          <DynamicTabs product={product} productId={product.ProductId} />
        )}
        <h2 className="view-product-similar-products">Similar Products</h2>
        <div className="view-product-card-container">
          <HorizontalCarousel
            products={relatedProducts}
            onMainImageClick={handleCardClick}
          />
        </div>
      </div>
    </>
  );
};

export default ViewProduct;
