// validation.js
export const validateName = (name) => {
  if (!/^[a-zA-Z ]+$/.test(name)) {
    return 'Name should contain only letters';
  }
  if (name.length > 100) {
    return 'Name should not exceed 100 characters';
  }
  return '';
};

export const validateEmail = (email) => {
  if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
    return 'Invalid email address';
  }
  return '';
};

export const validatePassword = (password) => {
  if (password.length < 8 || password.length > 20) {
    return 'Password should be between 8 and 20 characters';
  }
  return '';
};

export const validateNumber = (number) => {
  if (!/^[0-9]+$/.test(number)) {
    return 'Should contain only numbers';
  }
  if (number.length > 15) {
    return 'Should not exceed 15 characters';
  }
  return '';
};

export const validateAddress = (address) => {
  if (address.length > 200) {
    return 'Address should not exceed 200 characters';
  }
  return '';
};

export const validatePostcode = (postcode) => {
  if (postcode.length > 20) {
    return 'Should not exceed 20 characters';
  }
  return '';
};

export const validateProfileForm = (profile) => {
  let errors = {};
  errors.firstName = validateName(profile.firstName);
  errors.lastName = validateName(profile.lastName);
  errors.email = validateEmail(profile.email);
  errors.contactNumber = validateNumber(profile.contactNumber);
  errors.addressLine1 = validateAddress(profile.addressLine1);
  errors.addressLine2 = validateAddress(profile.addressLine2);
  errors.city = validateName(profile.city);
  errors.postcode = validatePostcode(profile.postcode);
  return errors;
};

export const validatePasswordForm = (passwords) => {
  let errors = {};
  errors.currentPassword = validatePassword(passwords.currentPassword);
  errors.newPassword = validatePassword(passwords.newPassword);
  errors.confirmNewPassword = passwords.newPassword !== passwords.confirmNewPassword
    ? 'New password and confirm new password do not match.'
    : '';
  return errors;
};

// Add validation for billing and shipping forms
export const validateBillingForm = (billing) => {
  let errors = {};
  errors.billingAddress = validateAddress(billing.billingAddress);
  errors.billingCity = validateName(billing.billingCity);
  errors.billingPostcode = validatePostcode(billing.billingPostcode);
  errors.billingEmail = validateEmail(billing.billingEmail);
  return errors;
};

export const validateShippingForm = (shipping) => {
  let errors = {};
  errors.shippingAddress = validateAddress(shipping.shippingAddress);
  errors.shippingCity = validateName(shipping.shippingCity);
  errors.shippingPostcode = validatePostcode(shipping.shippingPostcode);
  errors.shippingEmail = validateEmail(shipping.shippingEmail);
  return errors;
};
