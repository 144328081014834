import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import BannerImage from '../../../assests/ProductCard/Banner Image.png'
import BannerImage2 from '../../../assests/Banner-image 2.png'
import BannerImage3 from '../../../assests/Banner-image 3.png'

const ProductBanner = () => {
  return (
    <Container className="mb-4">
      <Row>
        <Col md={6}>
          <img 
            src={BannerImage}
            alt='Product 1' 
            className="img-fluid"
            style={{ objectFit: 'cover', height: '100%', borderRadius: '12px'}} 
          />
        </Col>
        <Col md={6}>
          <Row>
            <Col xs={12} className="mb-2">
              <img 
                src={BannerImage2} 
                alt="" 
                className="img-fluid"
                style={{ objectFit: 'cover', height: '100%' }} 
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <img 
                src={BannerImage3} 
                alt="Product 3" 
                className="img-fluid"
                style={{ objectFit: 'cover', height: '100%' }} 
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default ProductBanner;
