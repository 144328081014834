import { API_BASE_URL } from "../config/config";

export const fetchOrdersByUserId = async (userId) => {
  try {
    const response = await fetch(`${API_BASE_URL}user/order/all/?id=${userId}`);
    if (!response.ok) {
      throw new Error('Failed to fetch orders');
    }
    const result = await response.json();
    return result.data || [];
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const fetchOrderByInvoiceNumber = async (invoiceNumber) => {
  try {
    const response = await fetch(`${API_BASE_URL}user/order/single/?id=${invoiceNumber}`);
    if (!response.ok) {
      throw new Error('Failed to fetch order');
    }
    const result = await response.json();
    return result.data || null;
  } catch (error) {
    console.error(error);
    return null;
  }
};
