import React, { useEffect, useState } from 'react';
import { fetchAllTestimonials } from '../../../Services/testimonialsServices';
import avatar from '../../../assests/avatar.png';
import './Testimonials.css';

// Review Card Component
const ReviewCard = ({ review }) => {
  const renderStars = (rating) => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(
        <span key={i} className={i <= rating ? "text-warning" : "text-secondary"}>
          <i className="fas fa-star"></i>
        </span>
      );
    }
    return stars;
  };

  const getTimeAgo = (dateCreated) => {
    const intervals = [
      { label: 'year', seconds: 31536000 },
      { label: 'month', seconds: 2592000 },
      { label: 'day', seconds: 86400 },
      { label: 'hour', seconds: 3600 },
      { label: 'minute', seconds: 60 },
    ];

    const now = new Date();
    const created = new Date(dateCreated);
    const diffInSeconds = Math.floor((now - created) / 1000);

    for (const interval of intervals) {
      const count = Math.floor(diffInSeconds / interval.seconds);
      if (count > 0) {
        return `${count} ${interval.label}${count > 1 ? 's' : ''} ago`;
      }
    }

    return 'Just now';
  };

  return (
    <div className="col-md-4 col-sm-12 mb-4">
      <div className="card border-0 shadow-sm h-100">
        <div className="card-body position-relative">
          <img
            src={avatar}
            alt="avatar"
            className="avatar-image"
          />
          <div className="d-flex justify-content-between align-items-center mb-3">
            <div className="d-flex align-items-center">
              <div className="star-rating">{renderStars(review.Rating)}</div>
              <span className="review-time ml-3">{getTimeAgo(review.DateCreated)}</span>
            </div>
          </div>
          <p className="mt-3 review-text">{review.ReviewText}</p>
          <p className="review-name mb-0">-{review.ReviewerName}</p>
        </div>
      </div>
    </div>
  );
};

// Pagination Controls Component
const PaginationControls = ({ currentPage, totalPages, onNext, onPrevious }) => (
  <div className="pagination-controls d-flex justify-content-center my-4">
    <button
      className="pagination-button"
      onClick={onPrevious}
      disabled={currentPage === 1}
    >
      <i className="fas fa-arrow-left"></i>
    </button>
    <button
      className="pagination-button"
      onClick={onNext}
      disabled={currentPage === totalPages}
    >
      <i className="fas fa-arrow-right"></i>
    </button>
  </div>
);

// Main Testimonials Component
const Testimonials = () => {
  const [reviews, setReviews] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [reviewsPerPage, setReviewsPerPage] = useState(6); // Default: 3 columns, 2 rows

  useEffect(() => {
    const fetchTestimonials = async () => {
      try {
        const testimonials = await fetchAllTestimonials();
        setReviews(testimonials);
      } catch (error) {
        console.error('Error fetching testimonials:', error);
      }
    };

    fetchTestimonials();
  }, []);

  useEffect(() => {
    // Update reviewsPerPage based on screen size
    const updateReviewCount = () => {
      if (window.innerWidth <= 576) {
        setReviewsPerPage(1);
      } else {
        setReviewsPerPage(6);
      }
    };

    updateReviewCount();
    window.addEventListener('resize', updateReviewCount);

    return () => {
      window.removeEventListener('resize', updateReviewCount);
    };
  }, []);

  useEffect(() => {
    if (reviewsPerPage === 1) {
      // Auto-scroll reviews in responsive mode
      const intervalId = setInterval(() => {
        handleNextPage();
      }, 2000); // Change slide every 3 seconds

      return () => clearInterval(intervalId);
    }
  }, [currentPage, reviewsPerPage]);

  // Calculate the total pages
  const totalPages = Math.ceil(reviews.length / reviewsPerPage);

  // Determine the reviews to display on the current page
  const indexOfLastReview = currentPage * reviewsPerPage;
  const indexOfFirstReview = indexOfLastReview - reviewsPerPage;
  const currentReviews = reviews.slice(indexOfFirstReview, indexOfLastReview);

  // Pagination navigation functions
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    } else {
      setCurrentPage(1); // Loop back to first page
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    } else {
      setCurrentPage(totalPages); // Loop to last page
    }
  };

  return (
    <div className="container-fluid wider-container my-5">
      <div className="row">
        {currentReviews.map((review) => (
          <ReviewCard key={review.TestimonialId} review={review} />
        ))}
      </div>
      <PaginationControls
        currentPage={currentPage}
        totalPages={totalPages}
        onNext={handleNextPage}
        onPrevious={handlePreviousPage}
      />
    </div>
  );
};

export default Testimonials;
