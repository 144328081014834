import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import './FilterSidebar.css'; // Import the CSS file for custom styles

const FilterSidebar = () => {
  const [priceRange, setPriceRange] = useState(["Min", "Max"]);

  const handleMinChange = (e) => {
    const value = e.target.value;
    const newMin = value === "" ? "Min" : parseInt(value, 10);
    setPriceRange([newMin, priceRange[1]]);
  };

  const handleMaxChange = (e) => {
    const value = e.target.value;
    const newMax = value === "" ? "Max" : parseInt(value, 10);
    setPriceRange([priceRange[0], newMax]);
  };

  const handleApply = () => {
    // Implement the logic for applying the filter here
    console.log(`Applying price range: Min - ${priceRange[0]}, Max - ${priceRange[1]}`);
  };

  return (
    <div className="filter">
      <div className="filter-sidebar p-4 bg-light border rounded">
        <h5 className="text-left">Categories</h5>
        <Form>
          <div className="ml-3 Categories-section">
            <Form.Check type="radio" name="category" id="category1" label="Frocks" className="my-2" />
            <Form.Check type="radio" name="category" id="category2" label="Blouses" className="my-2" />
            <Form.Check type="radio" name="category" id="category3" label="Shirts" className="my-2" />
          </div>

          <h5 className="text-left">Price Range</h5>
          <div className="price-range-buttons">
            <input
              type="number"
              value={priceRange[0] !== "Min" ? priceRange[0] : ""} // Show blank if initial state
              onChange={handleMinChange}
              className="price-input"
              placeholder="Min" 
            />
            <input
              type="number"
              value={priceRange[1] !== "Max" ? priceRange[1] : ""} // Show blank if initial state
              onChange={handleMaxChange}
              className="price-input"
              placeholder="Max" 
            />
            <Button variant="dark" size="sm" onClick={handleApply} className="apply-button">
              Apply
            </Button>
          </div>

          <h5 className="text-left">Size</h5>
          <div className="ml-3 Size-section">
            <Form.Check type="radio" name="size" id="size1" label="XS" className="my-2" />
            <Form.Check type="radio" name="size" id="size2" label="S" className="my-2" />
            <Form.Check type="radio" name="size" id="size3" label="M" className="my-2" />
            <Form.Check type="radio" name="size" id="size4" label="L" className="my-2" />
            <Form.Check type="radio" name="size" id="size5" label="XL" className="my-2" />
            <Form.Check type="radio" name="size" id="size6" label="2XL" className="my-2" />
          </div>

          <h5 className="text-left">Availability</h5>
          <div className="ml-3 availability-section">
            <Form.Check type="radio" name="availability" id="availability1" label="In Stock" className="my-2" />
            <Form.Check type="radio" name="availability" id="availability2" label="Out of Stock" className="my-2" />
          </div>
        </Form>
      </div>
    </div>
  );
};

export default FilterSidebar;
