import React, { useState, useEffect } from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import toast styles
import './Returns.css'; 
import { fetchReasons } from '../../Services/ReturnServices';
import { fetchOrderByInvoiceNumber } from '../../Services/profileServices'; // Import the fetch function for order details
import { useParams } from 'react-router-dom'; // To get the invoice number from URL params
import axios from 'axios'; // Import axios for making API requests
import { CREATE_RETURNS_URL, VALIDATE_ORDER_URL } from '../../config/config'; // Import the validate order URL

const ReturnsManagement = () => {
  const { invoiceNumber, orderId } = useParams(); // Get the invoice number and order ID from URL
  const [productName, setProductName] = useState('');
  const [reason, setReason] = useState('');
  const [reasonsList, setReasonsList] = useState([]); // State to store reasons fetched from API
  const [photos, setPhotos] = useState([]); // State to store Base64 encoded images
  const [description, setDescription] = useState(''); // Changed from comment to description
  const [errors, setErrors] = useState({});
  const [isDragging, setIsDragging] = useState(false); // State to manage drag-over styling
  const [orderDetails, setOrderDetails] = useState([]); // State to store order details for the product dropdown
  const [orderIsValid, setOrderIsValid] = useState(false); // State to track if the order is valid
  const [orderIdState, setOrderId] = useState(null); // State to store the order ID
  const [userId, setUserId] = useState(null); // State to store the CustomerId (userId)
  const [isReturnWindowValid, setIsReturnWindowValid] = useState(true); // State to check if return window is valid

  useEffect(() => {
    // Validate the order by invoice number and order ID
    const validateOrder = async () => {
      try {
        const response = await axios.post(VALIDATE_ORDER_URL, {
          invoiceNumber,
          orderId
        });

        if (response.data.status === 1) {
          setOrderIsValid(true); // Set order as valid
        } else {
          setOrderIsValid(false);
          toast.error('Invalid invoice number or order ID.');
        }
      } catch (error) {
        console.error('Error validating order:', error);
        setOrderIsValid(false);
        toast.error('Failed to validate the order.');
      }
    };

    // Fetch reasons on component mount
    const loadReasons = async () => {
      try {
        const reasons = await fetchReasons();
        setReasonsList(reasons); // Set reasons fetched from API
      } catch (error) {
        console.error('Failed to fetch reasons:', error);
      }
    };

    // Fetch order details by invoice number
    const fetchOrderDetails = async () => {
      try {
        console.log("Fetching order for Invoice Number:", invoiceNumber);
        const orderData = await fetchOrderByInvoiceNumber(invoiceNumber);
        console.log("Fetched Order Data:", orderData);

        if (orderData && orderData.length > 0) {
          const order = orderData[0];
          setOrderDetails(order.OrderDetails || []); // Set the order details to the state
          setOrderId(order.OrderId); // Set the OrderId to state
          setUserId(order.CustomerId); // Set the CustomerId to the userId state
          
          // Calculate the return window validity
          const dateCreated = new Date(order.DateCreated); // Assuming DateCreated is available in the order data
          const currentDate = new Date();
          const diffInTime = currentDate - dateCreated;
          const diffInDays = diffInTime / (1000 * 3600 * 24); // Convert time difference to days
          
          if (diffInDays > 14) {
            setIsReturnWindowValid(false); 
            toast.error('Return window is overdue. You cannot create a return for this order.');
          } else {
            setIsReturnWindowValid(true); 
          }
          
        } else {
          toast.error('Order not found. Please check the invoice number.');
        }
      } catch (error) {
        console.error("Error fetching order:", error);
        toast.error('Failed to fetch order details. Please try again later.');
      }
    };

    validateOrder(); // Validate the order first
    loadReasons(); 
    fetchOrderDetails();
  }, [invoiceNumber, orderId]);

  // Function to handle file input changes and encode images
  const handlePhotoChange = (files) => {
    const newFiles = [...files];
    const remainingSlots = 3 - photos.length; // Calculate how many more images can be added

    if (newFiles.length > remainingSlots) {
      toast.error(`You can only upload up to 3 images. You have ${photos.length} uploaded already.`);
      return;
    }

    const encodePromises = newFiles.map((file) => encodeFileToBase64(file));

    Promise.all(encodePromises)
      .then((encodedImages) => {
        setPhotos((prevPhotos) => [...prevPhotos, ...encodedImages]); // Append new images
      })
      .catch((error) => {
        console.error('Error encoding images:', error); // Handle any encoding errors
      });
  };

  // Function to encode the file into Base64 format
  const encodeFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  // Function to remove an image from the list
  const handleRemovePhoto = (index) => {
    setPhotos((prevPhotos) => prevPhotos.filter((_, i) => i !== index));
  };

  // Drag and Drop Handlers
  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    const files = e.dataTransfer.files;
    if (files && files.length > 0) {
      handlePhotoChange(files);
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!productName) newErrors.productName = 'Product name is required';
    if (!reason) newErrors.reason = 'Reason is required';
    if (photos.length === 0) newErrors.photos = 'At least one photo is required';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm() || !isReturnWindowValid) return; // Stops further execution if the form is not valid or return window has expired

    const selectedReason = reasonsList.find((r) => r.reason === reason); // Find the selected reason object
    const selectedOrderDetail = orderDetails.find((item) => item.Title === productName); // Find the selected product

    // Construct the payload to match the required format
    const payload = {
      OrderId: orderIdState ? parseInt(orderIdState, 10) : null, // Convert OrderId to an integer
      Description: description, // Description from the form
      ProductId: selectedOrderDetail ? parseInt(selectedOrderDetail.ProductId, 10) : null, // Convert ProductId to an integer
      ReasonId: selectedReason ? parseInt(selectedReason.id, 10) : null, // Convert ReasonId to an integer
      Status: 'Pending', // Default status
      Images: photos, // Base64 encoded images
      UserId: userId ? parseInt(userId, 10) : null // Convert userId (CustomerId) to an integer
    };

    console.log('Payload:', payload); 

    try {
      // Make an API request to create a return
      const response = await axios.post(CREATE_RETURNS_URL, payload);
      console.log('Return Created:', response.data);
      toast.success('Return request created successfully!');
    } catch (error) {
      console.error('Error creating return:', error);
      toast.error('Failed to create return request. Please try again later.');
    }
  };

  return (
    <Container className="custom-form-container">
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} /> {/* Toast Container */}
      {!orderIsValid ? (
        <h2 className="custom-form-title">Invalid Invoice Number or Order ID</h2>
      ) : (
        <>
          <h2 className="custom-form-title">Return Create</h2>
          {!isReturnWindowValid && (
            <p style={{ color: 'red', fontWeight: 'bold' }}>The return window has expired. You cannot create a return for this order.</p>
          )}
          <Form onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Col md={6}>
                <Form.Group controlId="productName">
                  <Form.Label className="custom-form-label">Product Name</Form.Label>
                  <Form.Control
                    as="select"
                    value={productName}
                    onChange={(e) => setProductName(e.target.value)}
                    className="custom-input"
                    isInvalid={!!errors.productName}
                    disabled={!isReturnWindowValid} // Disable if return window is invalid
                  >
                    <option value="">Select the product</option>
                    {orderDetails.map((item) => (
                      <option key={item.Id} value={item.Title}>
                        {item.Title || "Unnamed Product"}
                      </option>
                    ))}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.productName}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="reason">
                  <Form.Label className="custom-form-label">Reason</Form.Label>
                  <Form.Control
                    as="select"
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                    className="custom-input"
                    isInvalid={!!errors.reason}
                    disabled={!isReturnWindowValid} // Disable if return window is invalid
                  >
                    <option value="">Select the reason</option>
                    {reasonsList.map((r) => (
                      <option key={r.id} value={r.reason}>
                        {r.reason}
                      </option>
                    ))}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.reason}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <Form.Group controlId="description" className="mb-3">
              <Form.Label className="custom-form-label">Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                maxLength={500}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Describe the issue with the product"
                disabled={!isReturnWindowValid} // Disable if return window is invalid
              />
            </Form.Group>

            <Form.Group controlId="photos" className="mb-3">
              <Form.Label className="custom-form-label">Upload Photos</Form.Label>
              <div
                className={`custom-file-upload ${isDragging ? 'dragging' : ''}`} // Add dragging class when dragging over
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
              >
                <i className="fa-solid fa-right-left"></i>
                <label htmlFor="fileInput">
                  Drag & Drop files here or Click to upload<br />PNG or JPG (max. 5MB)<br />Upload up to 3 Photos
                </label>
                <input
                  type="file"
                  id="fileInput"
                  accept="image/png, image/jpeg"
                  multiple
                  onChange={(e) => handlePhotoChange(e.target.files)}
                  style={{ display: 'none' }}
                  disabled={!isReturnWindowValid} // Disable if return window is invalid
                />
              </div>
              <div className="photo-previews">
                {photos.map((photo, index) => (
                  <div key={index} className="photo-container">
                    <img src={photo} alt="Preview" className="photo-preview" />
                    <button
                      type="button"
                      className="remove-photo-btn"
                      onClick={() => handleRemovePhoto(index)}
                    >
                      &times;
                    </button>
                  </div>
                ))}
              </div>
            </Form.Group>

            <Button className="custom-button" type="submit" disabled={!isReturnWindowValid}>
              Create
            </Button>
          </Form>
        </>
      )}
    </Container>
  );
};

export default ReturnsManagement;
