import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ProductCard from '../../components/common/ProductCardComponent/Index';
import { fetch_Products } from '../../Services/ProductServices';
import Spinner from '../../components/common/loadingSpinnercomponent/Spinner';
import { addToCart } from '../../slices/CartSlice';
import "./Wishlist.css";

const WishlistPage = () => {
  const [wishlistProducts, setWishlistProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const wishlistProductIds = useSelector(state => state.wishlist.wishlistProductIds);

  useEffect(() => {
    const loadWishlistProducts = async () => {
      try {
        const products = await fetch_Products();
        const filteredProducts = products.filter(product => wishlistProductIds.includes(product.ProductId));
        console.log('Fetched products for wishlist:', filteredProducts); // Debugging fetched products
        setWishlistProducts(filteredProducts);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    if (wishlistProductIds.length) {
      loadWishlistProducts();
    } else {
      setLoading(false);
    }
  }, [wishlistProductIds]);

  const handleAddToCart = () => {
    wishlistProducts.forEach(product => {
      // Iterate over each color-size combination
      product.Colors.forEach(colorSizeVariant => {
        const payload = {
          ProductId: product.ProductId,
          Title: product.Title,
          Price: product.Price,
          quantity: 1, // Default to 1 for each variant
          Size: colorSizeVariant.Size,
          Color: colorSizeVariant.Color
        };

        console.log('Payload being passed to addToCart:', payload); // Debugging payload being passed to cart

        dispatch(addToCart(payload));
      });
    });

    // Clear the wishlist from local storage
    localStorage.removeItem('wishlistProductIds');
    setWishlistProducts([]);
  };

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <div className="wishlist-error">Error loading wishlist products: {error.message}</div>;
  }

  return (
    <div className="wishlist-page">
      <h1 className="wishlist-heading">Wishlist</h1>
      <hr />
      <div className="wishlist-count-container">
        <span className="wishlist-count">{wishlistProducts.length} items</span>
      </div>
      {wishlistProducts.length === 0 ? (
        <p className="wishlist-no-products">No products are available</p>
      ) : (
        <div className="wishlist-content">
          <div className="wishlist-grid">
            {wishlistProducts.map(product => (
              <ProductCard key={product.ProductId} product={product} />
            ))}
          </div>
          <div className="wishlist-button-container">
            <button className="wishlist-add-to-cart-button" onClick={handleAddToCart}>
              <i className="fa-solid fa-cart-shopping"></i>
              Add All to Cart
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default WishlistPage;
