import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./productcarouselComponent.css";
import ProductCard from "../ProductCardComponent/Index";

const NextArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div className={`${className} custom-arrow`} onClick={onClick}>
      <i
        className="fas fa-chevron-right"
        style={{ fontSize: 24, color: "black" }}
      ></i>
    </div>
  );
};

const PrevArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div className={`${className} custom-arrow`} onClick={onClick}>
      <i
        className="fas fa-chevron-left"
        style={{ fontSize: 24, color: "black" }}
      ></i>
    </div>
  );
};

const HorizontalCarousel = ({ products, onMainImageClick }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1, // Set to 1
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1, // Set to 1
          infinite: true,
          dots: false,
          // Removed arrows from here
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1, // Set to 1
          infinite: true,
          dots: false,
          // Removed arrows from here
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          // Removed arrows from here
        },
      },
    ],
  };

  return (
    <div style={{ width: "100%", margin: "0 auto" }}>
      <Slider {...settings}>
        {products.map((product) => (
          <ProductCard
            key={product.ProductId}
            product={product}
            onMainImageClick={onMainImageClick}
          />
        ))}
      </Slider>
    </div>
  );
};

export default HorizontalCarousel;
