import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import "./ProductView.css";
import shoppingBagImage from "../../../assests/shopping-bag 1.png";
import Mintpay from "../../../assests/ProductCard/Mintpay.png";
import Koko from "../../../assests/ProductCard/Koko.png";
import { Image_URL } from "../../../config/config";
import { addToCart } from "../../../slices/CartSlice";

const ProductView = () => {
  const { id } = useParams();
  const product = useSelector((state) => state.product.selectedProduct);
  const dispatch = useDispatch();
  const [selectedSize, setSelectedSize] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [showSizeChart, setShowSizeChart] = useState(false);
  const [selectedColor, setSelectedColor] = useState("");
  const [mainImage, setMainImage] = useState("");

  useEffect(() => {
    if (product.Colors.length > 0) {
      const initialSize = product.Colors[0].Size;
      setSelectedSize(initialSize);
      const initialColor = product.Colors.find(
        (color) => color.Size === initialSize
      );
      if (initialColor) {
        handleColorClick(initialColor);
      }
    }
  }, [product]);

  const handleSizeSelect = (size) => {
    setSelectedSize(size);
    const availableColors = product.Colors.filter(
      (color) => color.Size === size
    );
    const colorMatch = availableColors.find(
      (color) => color.Color === selectedColor
    );
    if (colorMatch) {
      handleColorClick(colorMatch);
    } else if (availableColors.length > 0) {
      handleColorClick(availableColors[0]);
    }
  };

  const handleQuantityChange = (event) => {
    setQuantity(parseInt(event.target.value, 10));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const productToAdd = {
      ProductId: product.ProductId,
      Title: product.Title,
      Price: product.Price,
      Color: selectedColor,
      Size: selectedSize,
      quantity: quantity,
      Image: mainImage,
    };
    dispatch(addToCart(productToAdd));
  };

  const handleSizeChartClick = () => {
    setShowSizeChart(!showSizeChart);
  };

  const parseKeyFeatures = (keyFeatures) => {
    try {
      return JSON.parse(keyFeatures);
    } catch (error) {
      console.error("Error parsing key features:", error);
      return [];
    }
  };

  const getUniqueSizes = (colors) => {
    const uniqueSizes = new Set();
    colors.forEach((color) => {
      uniqueSizes.add(color.Size);
    });
    return Array.from(uniqueSizes);
  };

  const handleColorClick = (color) => {
    const firstImage = color.Sources.split(",")[0].trim();
    setSelectedColor(color.Color);
    setMainImage(firstImage);
  };

  const keyFeatures = parseKeyFeatures(product.KeyFeatures);
  const uniqueSizes = getUniqueSizes(product.Colors);

  const uniqueColorsForSize = (selectedSize) => {
    const colorSet = new Set();
    return product.Colors.filter(
      (color) =>
        color.Size === selectedSize &&
        !colorSet.has(color.Color) &&
        colorSet.add(color.Color)
    );
  };

  return (
    <form className="product-view" onSubmit={handleSubmit}>
      <div className="product-image">
        <img
          src={Image_URL + "products/thumbnails/" + mainImage}
          alt={product.Title}
          onError={(e) => {
            e.target.src =
              "https://jbadmin.alephaz.com/assets/img/placeholder-image.jpg";
          }}
        />
      </div>
      <div className="product-details">
        <h1>{product.Title}</h1>
        <p className="description">{product.Description}</p>
        <p className="price">Rs {product.Price}</p>
        <div className="installment">
          <p>or 3 installments of Rs {Math.floor(product.Price / 3)} with</p>
          <div className="payment-logos-container">
            <img src={Mintpay} alt="MintPay" className="payment-logo me-2" />
            <p className="installments">or</p>
            <img src={Koko} alt="Koko" className="payment-logo" />
          </div>
        </div>
        <p className="size-label">Size:</p>
        <div className="size-options">
          {uniqueSizes.map((size) => (
            <button
              type="button"
              key={size}
              onClick={() => handleSizeSelect(size)}
              className={selectedSize === size ? "selected" : ""}
            >
              {size}
            </button>
          ))}
        </div>
        <p className="view-size-chart" onClick={handleSizeChartClick}>
          View Size Chart
        </p>
        {showSizeChart && (
          <div className="size-chart">
            <img
              src="https://www.glamly.com/cdn/shop/articles/MicrosoftTeams-image_46_1200x1200.png?v=1698044645"
              alt="Size Chart"
              className="size-chart-image"
            />
          </div>
        )}
        <div className="color-dots-container">
          {uniqueColorsForSize(selectedSize).map((color, index) => (
            <div
              key={index}
              className="color-dot"
              style={{ backgroundColor: color.Color }}
              title={color.Color}
              onClick={() => handleColorClick(color)}
            ></div>
          ))}
        </div>
        <div className="quantity-cart">
          <input
            type="number"
            min="1"
            value={quantity}
            onChange={handleQuantityChange}
          />
          <button type="submit" className="add-to-cart">
            <img
              src={shoppingBagImage}
              alt="shopping bag"
              className="shopping-bag-icon"
            />{" "}
            Add to Cart
          </button>
        </div>
        <div className="product-info">
          <div>
            <h4>Key Points</h4>
            <div dangerouslySetInnerHTML={{ __html: product.KeyPoints }} />
          </div>
          <div>
            <h4>Key Features</h4>
            {keyFeatures.map((feature, index) => (
              <div key={index}>
                <strong>{feature.feature}:</strong>
                <ul>
                  {feature.points.map((point, idx) => (
                    <li key={idx} dangerouslySetInnerHTML={{ __html: point }} />
                  ))}
                </ul>
              </div>
            ))}
          </div>
          <ul>
            <li>Category ID: {product.CategoryId}</li>
            <li>SubCategory ID: {product.SubCategoryId}</li>
            <li>Weight: {product.Weight}g</li>
          </ul>
          {Array.from({ length: 3 }, (_, index) => (
            <p key={index}>
              Model Height: {product.modelHeight} wearing size M. Please bear in
              mind that the photo may be slightly different from the actual item
              in terms of color due to lighting conditions or the display used
              to view.
            </p>
          ))}
          <p className="note">{product.note}</p>
        </div>
      </div>
    </form>
  );
};

export default ProductView;
