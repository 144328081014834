import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  billingAddress: {
    firstName: '',
    lastName: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    postcode: '',
    email: '',
    contactNumber: '',
  },
  shippingAddress: {
    firstName: '',
    lastName: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    postcode: '',
    email: '',
    contactNumber: '',
  },
  selectedDeliveryOption: '',
  useShippingAsBilling: false,
  discount: 0,
  subtotal: 2000,
  shipping: 350,
  total: 2350
};

const checkoutSlice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    setBillingAddress(state, action) {
      state.billingAddress = action.payload;
      if (state.useShippingAsBilling) {
        state.shippingAddress = action.payload;
      }
    },
    setShippingAddress(state, action) {
      state.shippingAddress = action.payload;
    },
    setDeliveryOption(state, action) {
      state.selectedDeliveryOption = action.payload;
    },
    setUseShippingAsBilling(state, action) {
      state.useShippingAsBilling = action.payload;
      if (action.payload) {
        state.shippingAddress = state.billingAddress;
      }
    },
    applyDiscount(state, action) {
      state.discount = action.payload;
    },
    calculateTotal(state) {
      state.total = state.subtotal + state.shipping - state.discount;
    },
    updateQuantity(state, action) {
      
    },
  },
});

export const {
  setBillingAddress,
  setShippingAddress,
  setDeliveryOption,
  setUseShippingAsBilling,
  applyDiscount,
  calculateTotal,
  updateQuantity
} = checkoutSlice.actions;

export default checkoutSlice.reducer;
