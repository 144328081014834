import { API_BASE_URL } from "../config/config";

export const fetchCategories = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}user/master-data/category/all/`);
    if (!response.ok) {
      throw new Error('Failed to fetch categories');
    }
    const result = await response.json();
    return result.data || [];
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const fetchSubCategories = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}user/master-data/subcategory/all/`);
    if (!response.ok) {
      throw new Error('Failed to fetch subcategories');
    }
    const result = await response.json();
    return result.data || [];
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const fetchProductsByCategory = async (slug) => {
  try {
    const response = await fetch(`${API_BASE_URL}user/products/get-products-by-category/?slug=${slug}`);
    if (!response.ok) {
      throw new Error('Failed to fetch products by category');
    }
    const result = await response.json();
    return result.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const fetchProductsBySubCategory = async (slug) => {
  try {
    const response = await fetch(`${API_BASE_URL}user/products/get-products-by-subcategory/?slug=${slug}`);
    if (!response.ok) {
      throw new Error('Failed to fetch products by subcategory');
    }
    const result = await response.json();
    return result.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const fetchCollections = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}user/master-data/collection/all/`);
    if (!response.ok) {
      throw new Error('Failed to fetch collections');
    }
    const result = await response.json();
    return result.data || [];
  } catch (error) {
    console.error(error);
    return [];
  }
};


export const fetchProductsByCollection = async (slug) => {
  try {
    const response = await fetch(`${API_BASE_URL}user/products/get-products-by-collection/?slug=${slug}`);
    if (!response.ok) {
      throw new Error('Failed to fetch products by collection');
    }
    const result = await response.json();
    return result.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};
