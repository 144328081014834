import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { 
  setFirstName,
  setLastName,
  setEmail,
  setPhone,
  setMessage,
  setFirstNameError,
  setLastNameError,
  setEmailError,
  setPhoneError,
  setMessageError
} from '../../slices/contactSlice';
import "./ContactUs.css";
import contactusImage from "../../assests/contactus.jpeg";
import {
  validateName,
  validateEmail,
  validateNumber,
} from "../common/Validation";
import { CONTACT_FORM_URL } from '../../config/config';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ContactUs = () => {
  const dispatch = useDispatch();
  const {
    firstName,
    lastName,
    email,
    phone,
    message,
    firstNameError,
    lastNameError,
    emailError,
    phoneError,
    messageError,
  } = useSelector((state) => state.contact);

  const handleSubmit = async (e) => {
    e.preventDefault();

    let isValid = true;

    const firstNameValidation = validateName(firstName);
    if (firstNameValidation) {
      dispatch(setFirstNameError(firstNameValidation));
      isValid = false;
    } else {
      dispatch(setFirstNameError(""));
    }

    const lastNameValidation = validateName(lastName);
    if (lastNameValidation) {
      dispatch(setLastNameError(lastNameValidation));
      isValid = false;
    } else {
      dispatch(setLastNameError(""));
    }

    const emailValidation = validateEmail(email);
    if (emailValidation) {
      dispatch(setEmailError(emailValidation));
      isValid = false;
    } else {
      dispatch(setEmailError(""));
    }

    const phoneValidation = validateNumber(phone);
    if (phoneValidation) {
      dispatch(setPhoneError(phoneValidation));
      isValid = false;
    } else {
      dispatch(setPhoneError(""));
    }

    if (message.length > 500) {
      dispatch(setMessageError("Message should not exceed 500 characters"));
      isValid = false;
    } else {
      dispatch(setMessageError(""));
    }

    if (isValid) {
      try {
        const response = await fetch(CONTACT_FORM_URL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            firstName,
            lastName,
            email,
            phone,
            message,
          }),
        });

        const data = await response.json();

        if (response.ok) {
          toast.success(data.message || "Thank you for your message. We'll get back to you soon.");
          // Reset form fields
          dispatch(setFirstName(''));
          dispatch(setLastName(''));
          dispatch(setEmail(''));
          dispatch(setPhone(''));
          dispatch(setMessage(''));
        } else {
          toast.error(data.message || 'An error occurred. Please try again.');
        }
      } catch (error) {
        toast.error('An error occurred. Please try again.');
      }
    }
  };

  return (
    <div className="contact-us-page">
      <div className="contact-us-grid">
        <div className="contact-us-form-container">
          <div className="contact-us-form">
            <form onSubmit={handleSubmit}>
              <div className="styleName">Contact Us</div>
              <p>Our friendly team would love to hear from you.</p>
              
              <div className="name-inputs">
                <div className="name-input">
                  <label htmlFor="first-name">First Name</label>
                  <input
                    type="text"
                    id="first-name"
                    placeholder="First name"
                    value={firstName}
                    onChange={(e) => dispatch(setFirstName(e.target.value))}
                    maxLength="100"
                    required
                  />
                  {firstNameError && (
                    <div className="error-message">{firstNameError}</div>
                  )}
                </div>
                <div className="name-input">
                  <label htmlFor="last-name">Last Name</label>
                  <input
                    type="text"
                    id="last-name"
                    placeholder="Last name"
                    value={lastName}
                    onChange={(e) => dispatch(setLastName(e.target.value))}
                    maxLength="100"
                    required
                  />
                  {lastNameError && (
                    <div className="error-message">{lastNameError}</div>
                  )}
                </div>
              </div>
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => dispatch(setEmail(e.target.value))}
                required
              />
              {emailError && <div className="error-message">{emailError}</div>}
              <label htmlFor="phone">Phone Number</label>
              <input
                type="tel"
                id="phone"
                placeholder="Enter your phone number"
                value={phone}
                onChange={(e) => dispatch(setPhone(e.target.value))}
                required
              />
              {phoneError && <div className="error-message">{phoneError}</div>}
              <label htmlFor="message">Message</label>
              <textarea
                id="message"
                rows="4"
                placeholder="Enter your message"
                value={message}
                onChange={(e) => dispatch(setMessage(e.target.value))}
                maxLength="500"
                required
              ></textarea>
              {messageError && (
                <div className="error-message">{messageError}</div>
              )}
              <button type="submit" className="submit-button">
                Send Message
              </button>
            </form>
          </div>
        </div>
        <div className="contact-us-image">
          <img src={contactusImage} alt="Contact Us Visual" />
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ContactUs;
