import React, { useState, useEffect } from 'react';
import { Navbar, Nav, Container, Offcanvas } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Logo from '../../../assests/logo.png';
import './Navbar.css';
import SearchModal from '../modalComponent/SearchModal/SearchModal';
import { fetchCategories, fetchSubCategories, fetchCollections } from '../../../Services/CategoryServices';

const NavbarComponent = () => {
  const [showCategoriesDropdown, setShowCategoriesDropdown] = useState(false);
  const [showCollectionsDropdown, setShowCollectionsDropdown] = useState(false);
  const [hoveredCategory, setHoveredCategory] = useState(null);
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [categories, setCategories] = useState([]);
  const [collections, setCollections] = useState([]);
  const [showOffcanvas, setShowOffcanvas] = useState(false);

  const wishlistCount = useSelector((state) => state.wishlist.wishlistProductIds.length);
  const cartCount = useSelector((state) => state.cart.items.length);
  const navigate = useNavigate();

  useEffect(() => {
    const getCategoriesAndCollections = async () => {
      try {
        const categories = await fetchCategories();
        const subCategories = await fetchSubCategories();
        const categoriesWithSubCategories = categories.map((category) => ({
          ...category,
          subCategories: subCategories.filter((subCategory) => subCategory.CategoryId === category.CategoryId),
        }));
        setCategories(categoriesWithSubCategories);
        const collections = await fetchCollections();
        setCollections(collections);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    getCategoriesAndCollections();
  }, []);

  const handleSearchModalOpen = () => setShowSearchModal(true);
  const handleSearchModalClose = () => setShowSearchModal(false);

  const handleCategoryClick = (slug) => {
    navigate(`/category/${slug}`);
    setShowOffcanvas(false);
  };
  const handleSubCategoryClick = (slug) => {
    navigate(`/subcategory/${slug}`);
    setShowOffcanvas(false);
  };
  const handleCollectionClick = (slug) => {
    navigate(`/collection/${slug}`);
    setShowOffcanvas(false);
  };

  const handleOffcanvasClose = () => setShowOffcanvas(false);
  const handleOffcanvasShow = () => setShowOffcanvas(true);

  const renderNavLinks = () => (
    <>
      <Nav.Link as={Link} to="/home">Home</Nav.Link>
      <Nav.Link as={Link} to="/product-list">Shop</Nav.Link>
      <div
        className="nav-item dropdown"
        onMouseEnter={() => setShowCategoriesDropdown(true)}
        onMouseLeave={() => setShowCategoriesDropdown(false)}
      >
        <Nav.Link className="nav-link d-flex align-items-center">
          Categories <i className="fa-solid fa-chevron-down ml-1"></i>
        </Nav.Link>
        {showCategoriesDropdown && (
          <div className="dropdown-menu show">
            {categories.map((category) => (
              <div
                key={category.CategoryId}
                className="dropdown-submenu"
                onMouseEnter={() => setHoveredCategory(category.CategoryId)}
                onMouseLeave={() => setHoveredCategory(null)}
              >
                <div className="dropdown-item" onClick={() => handleCategoryClick(category.Slug)}>
                  {category.Category}
                </div>
                {hoveredCategory === category.CategoryId && (
                  <div className="dropdown-menu show">
                    {category.subCategories.map((subCategory) => (
                      <div
                        key={subCategory.SubCategoryId}
                        className="dropdown-item"
                        onClick={() => handleSubCategoryClick(subCategory.Slug)}
                      >
                        {subCategory.SubCategory}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
      <div
        className="nav-item dropdown"
        onMouseEnter={() => setShowCollectionsDropdown(true)}
        onMouseLeave={() => setShowCollectionsDropdown(false)}
      >
        <Nav.Link className="nav-link d-flex align-items-center">
          Collections <i className="fa-solid fa-chevron-down ml-1"></i>
        </Nav.Link>
        {showCollectionsDropdown && (
          <div className="dropdown-menu show">
            {collections.map((collection) => (
              <div
                key={collection.CollectionId}
                className="dropdown-item"
                onClick={() => handleCollectionClick(collection.Slug)}
              >
                {collection.Collection}
              </div>
            ))}
          </div>
        )}
      </div>
      <Nav.Link as={Link} to="/about-us">About</Nav.Link>
      <Nav.Link as={Link} to="/contact-us">Contact Us</Nav.Link>
    </>
  );

  const renderOffcanvasLinks = () => (
    <>
      <Nav.Link as={Link} to="/home" onClick={handleOffcanvasClose}>Home</Nav.Link>
      <Nav.Link as={Link} to="/product-list" onClick={handleOffcanvasClose}>Shop</Nav.Link>
      <Nav.Link onClick={() => setShowCategoriesDropdown(!showCategoriesDropdown)}>
        Categories <i className={`fa-solid fa-chevron-${showCategoriesDropdown ? 'up' : 'down'} ml-1`}></i>
      </Nav.Link>
      {showCategoriesDropdown && categories.map((category) => (
        <React.Fragment key={category.CategoryId}>
          <Nav.Link className="pl-3" onClick={() => handleCategoryClick(category.Slug)}>{category.Category}</Nav.Link>
          {category.subCategories.map((subCategory) => (
            <Nav.Link
              key={subCategory.SubCategoryId}
              className="pl-5"
              onClick={() => handleSubCategoryClick(subCategory.Slug)}
            >
              {subCategory.SubCategory}
            </Nav.Link>
          ))}
        </React.Fragment>
      ))}
      <Nav.Link onClick={() => setShowCollectionsDropdown(!showCollectionsDropdown)}>
        Collections <i className={`fa-solid fa-chevron-${showCollectionsDropdown ? 'up' : 'down'} ml-1`}></i>
      </Nav.Link>
      {showCollectionsDropdown && collections.map((collection) => (
        <Nav.Link
          key={collection.CollectionId}
          className="pl-3"
          onClick={() => handleCollectionClick(collection.Slug)}
        >
          {collection.Collection}
        </Nav.Link>
      ))}
      <Nav.Link as={Link} to="/about-us" onClick={handleOffcanvasClose}>About</Nav.Link>
      <Nav.Link as={Link} to="/contact-us" onClick={handleOffcanvasClose}>Contact Us</Nav.Link>
    </>
  );

  return (
    <>
      <Navbar variant="dark" expand="lg" className="navbar">
        <Container className="d-flex align-items-center justify-content-between custom-container">
          <Navbar.Brand as={Link} to="/home" className="navbar-brand">
            <img src={Logo} className="logo" alt="Logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleOffcanvasShow} className="d-lg-none" />
          <Navbar.Collapse id="basic-navbar-nav" className="justify-content-center d-none d-lg-flex">
            <Nav className="nav-center">
              {renderNavLinks()}
            </Nav>
          </Navbar.Collapse>
          <div className="nav-icons ml-auto">
            <Link to="#" onClick={handleSearchModalOpen}>
              <i className="fa-solid fa-magnifying-glass"></i>
            </Link>
            <Link to="/profile">
              <i className="fa-regular fa-user"></i>
            </Link>
            <Link to="/cart" className="icon-container">
              {cartCount > 0 && <span className="cart-count">{cartCount}</span>}
              <i className="fa-solid fa-cart-shopping"></i>
            </Link>
            <Link to="/wishlist" className="icon-container">
              {wishlistCount > 0 && <span className="wishlist-count">{wishlistCount}</span>}
              <i className="fa-regular fa-heart"></i>
            </Link>
          </div>
        </Container>
      </Navbar>

      <Offcanvas show={showOffcanvas} onHide={handleOffcanvasClose} placement="end" className="d-lg-none">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Menu</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Nav className="flex-column">
            {renderOffcanvasLinks()}
          </Nav>
        </Offcanvas.Body>
      </Offcanvas>

      <SearchModal show={showSearchModal} handleClose={handleSearchModalClose} />
    </>
  );
};

export default NavbarComponent;
