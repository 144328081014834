import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import './SearchModal.css';
import { SEARCH_SUGGESTIONS_URL, Image_URL } from '../../../../config/config';
import placeholderImage from '../../../../assests/Banner-image 2.png';

const SearchModal = ({ show, handleClose }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const fetchSuggestions = useCallback(async (term) => {
    if (term.length > 2) {
      setIsLoading(true);
      try {
        const response = await fetch(`${SEARCH_SUGGESTIONS_URL}?kw=${encodeURIComponent(term)}`);
        if (response.ok) {
          const data = await response.json();
          if (data.status === 1 && Array.isArray(data.data)) {
            setSuggestions(data.data);
          } else {
            setSuggestions([]);
          }
        } else {
          console.error('Failed to fetch suggestions');
          setSuggestions([]);
        }
      } catch (error) {
        console.error('Error fetching suggestions:', error);
        setSuggestions([]);
      } finally {
        setIsLoading(false);
      }
    } else {
      setSuggestions([]);
    }
  }, []);

  useEffect(() => {
    if (searchTerm.length > 2) {
      const timeoutId = setTimeout(() => fetchSuggestions(searchTerm), 300);
      return () => clearTimeout(timeoutId);
    }
  }, [searchTerm, fetchSuggestions]);

  const handleSearch = () => {
    if (searchTerm.trim().length === 0) return;
    navigate(`/search?query=${encodeURIComponent(searchTerm)}`);
    handleClose();
  };

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSuggestionClick = (product) => {
    setSearchTerm(product.Title);
    navigate(`/search?query=${encodeURIComponent(product.Title)}`);
    handleClose();
  };

  const handleImageError = (event) => {
    event.target.src = placeholderImage;
  };

  const getImageUrl = (product) => {
    if (product.Colors && product.Colors.length > 0) {
      const firstColor = product.Colors[0];
      if (firstColor.Sources) {
        const firstImage = firstColor.Sources.split(',')[0];
        return `${Image_URL + "products/thumbnails/"}${firstImage}`;
      }
    }
    return placeholderImage;
  };

  useEffect(() => {
    if (show) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  }, [show]);

  if (!show) return null;

  return (
    <div className="search-modal-overlay" onClick={handleClose}>
      <div className="search-modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="search-input-container">
          <input
            type="text"
            className="search-input"
            placeholder="Search products..."
            autoFocus
            value={searchTerm}
            onChange={handleInputChange}
          />
          <button className="search-button" onClick={handleSearch}>
            <i className="fa-solid fa-magnifying-glass"></i>
          </button>
        </div>
        {isLoading && <div className="loading">Loading...</div>}
        {!isLoading && suggestions.length > 0 && (
          <ul className="search-suggestions">
            {suggestions.map((product) => (
              <li key={product.ProductId} onClick={() => handleSuggestionClick(product)} className="suggestion-item">
                <img
                  src={getImageUrl(product)}
                  alt={product.Title}
                  className="suggestion-image"
                  onError={handleImageError}
                />
                <div className="suggestion-text">
                  {product.Title}
                  <span className="product-price"> - ₹{product.Price}</span>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default SearchModal;