import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './Banner.css'; // Import the CSS file

const FooterBanner = () => {
  return (
    <div className="footer-banner">
      <Container>
        <Row>
          <Col className="icon-text">
            <div className="icon">
            <i className="fa-solid fa-truck"></i>
            </div>
            <div className="text">
              <h6>Islandwide Delivery</h6>
              <p>Islandwide cash on delivery</p>
            </div>
          </Col>
          <Col className="icon-text">
            <div className="icon">
            <i className="fa-solid fa-money-bill"></i>
            </div>
            <div className="text">
              <h6>Money Guarantee</h6>
              <p>30 days money back</p>
            </div>
          </Col>
          <Col className="icon-text">
            <div className="icon">
            <i className="fa-solid fa-headphones"></i>
            </div>
            <div className="text">
              <h6>24/7 Support</h6>
              <p>Friendly 24/7 support</p>
            </div>
          </Col>
          <Col className="icon-text">
            <div className="icon">
            <i className="fa-solid fa-credit-card"></i>
            </div>
            <div className="text">
              <h6>Secure Payment</h6>
              <p>All cards accepted</p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default FooterBanner;
