const API_BASE_URL = 'https://api.alephaz.com/jb/v1/';
const Image_URL = 'http://api.alephaz.com/jb/public/';
// const API_BASE_URL = 'http://localhost/ecom-api/v1/';
// const Image_URL = 'http://localhost/ecom-api/public/';
const LOGIN_URL = `${API_BASE_URL}user/auth/login/index1.php`;
const SIGNUP_URL = `${API_BASE_URL}user/auth/register/`;
const SAVE_ORDER_URL = `${API_BASE_URL}user/order/save/`;
const UPDATE_ORDER_URL = `${API_BASE_URL}user/order/update/`;
const RESET_PASSWORD_URL = `${API_BASE_URL}user/profile/change-password/`;
const Order_Details_URL = 'http://api.alephaz.com/jb/v1/user/order/all/';
const Get_By_Slug = 'get-product-by-slug/?slug='
const Shipping_Details_URL = `${API_BASE_URL}/user/profile/shipping-details/update/`;
const Related_Products_URL = `${API_BASE_URL}user/products/get-related-products/`;
const BILLING_UPDATE_URL = `${API_BASE_URL}user/profile/billing-details/update/`;
const SHIPPING_UPDATE_URL = `${API_BASE_URL}user/profile/shipping-details/update/`;
const PROFILE_EDIT_URL = `${API_BASE_URL}user/profile/edit/`;
const SEARCH_URL = `${API_BASE_URL}user/products/search/`;
const SEARCH_SUGGESTIONS_URL = `${API_BASE_URL}user/products/search-suggestions/`;
const FORGOT_PASSWORD_URL = `${API_BASE_URL}user/auth/forgot-password`;
const CONTACT_FORM_URL = `${API_BASE_URL}user/contact-us/index.php`;
const APPLY_COUPON_URL = `${API_BASE_URL}user/order/apply-coupon/`;

const GET_RETURNS_URL = `${API_BASE_URL}admin/returns/all/`
const CREATE_RETURNS_URL = `${API_BASE_URL}user/order/returns/create/`
const GET_REASONS_URL = `${API_BASE_URL}user/order/returns/reason/`
const VALIDATE_ORDER_URL = `${API_BASE_URL}user/order/returns/validate/`

export { 
  API_BASE_URL, 
  Image_URL, 
  LOGIN_URL, 
  SIGNUP_URL, 
  SAVE_ORDER_URL, Order_Details_URL,Get_By_Slug, 
  UPDATE_ORDER_URL, 
  RESET_PASSWORD_URL,
  BILLING_UPDATE_URL,
  PROFILE_EDIT_URL,
  SHIPPING_UPDATE_URL,
  SEARCH_URL,
  SEARCH_SUGGESTIONS_URL,
  Shipping_Details_URL,
  Related_Products_URL, 
  GET_RETURNS_URL,
  CREATE_RETURNS_URL,
  GET_REASONS_URL,
  VALIDATE_ORDER_URL,
  FORGOT_PASSWORD_URL,
  CONTACT_FORM_URL,
  APPLY_COUPON_URL
};