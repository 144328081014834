import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setModalOpen } from "../../../../slices/ProductSlice";
import { useNavigate } from "react-router-dom";
import Mintpay from "../../../../assests/ProductCard/Mintpay.png";
import Koko from "../../../../assests/ProductCard/Koko.png";
import "./Modal.css";
import shoppingBagImage from "../../../../assests/shopping-bag 1.png";
import { Image_URL } from "../../../../config/config";
import { addToCart } from "../../../../slices/CartSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import VerticalCarousel from "../../../Viewproduct/Carousel/Index.jsx"; // Adjust the path based on your file structure

const ProductViewModal = () => {
  const dispatch = useDispatch();
  const isModalOpen = useSelector((state) => state.product.isModalOpen);
  const product = useSelector((state) => state.product.selectedProduct);
  const [selectedSize, setSelectedSize] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [showSizeChart, setShowSizeChart] = useState(false);
  const [selectedColor, setSelectedColor] = useState("");
  const [mainImage, setMainImage] = useState("");
  const [colorImages, setColorImages] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (isModalOpen) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }

    return () => {
      document.body.classList.remove("modal-open");
    };
  }, [isModalOpen]);

  useEffect(() => {
    if (product && product.Colors && product.Colors.length > 0) {
      const initialColor = product.Colors[0];
      handleColorClick(initialColor);
    }
  }, [product]);

  const handleSizeSelect = (size) => {
    if (!product || !product.Colors) return;

    if (size === selectedSize) {
      setSelectedSize("");
      setSelectedColor("");
      setColorImages([]);
    } else {
      setSelectedSize(size);
      const availableColors = product.Colors.filter(
        (color) => color.Size === size
      );
      const colorMatch = availableColors.find(
        (color) => color.Color === selectedColor
      );
      if (colorMatch) {
        handleColorClick(colorMatch);
      } else if (availableColors.length > 0) {
        handleColorClick(availableColors[0]);
      }
    }
  };

  const handleQuantityChange = (event) => {
    setQuantity(parseInt(event.target.value, 10));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!selectedSize) {
      toast.error("Please select a size");
      return;
    }

    const productToAdd = {
      ProductId: product.ProductId,
      Title: product.Title,
      Price: product.Price,
      Color: selectedColor,
      Size: selectedSize,
      quantity: quantity,
      Image: mainImage,
    };

    dispatch(addToCart(productToAdd));
    toast.success("Product added to cart!");
    dispatch(setModalOpen(false));
  };

  const handleSizeChartClick = () => {
    setShowSizeChart(!showSizeChart);
  };

  const handleColorClick = (color) => {
    if (color && color.Sources) {
      const images = color.Sources.split(",").map((source) => source.trim());
      const firstImage = images[0];
      setSelectedColor(color.Color);
      setMainImage(firstImage);
      setColorImages(images);
    }
  };

  const getUniqueSizes = (colors) => {
    if (!colors || colors.length === 0) {
      return [];
    }
    const uniqueSizes = new Set();
    colors.forEach((color) => {
      uniqueSizes.add(color.Size);
    });
    return Array.from(uniqueSizes);
  };

  const getUniqueColors = () => {
    if (!product || !product.Colors) return [];
    const uniqueColors = new Set();
    return product.Colors.filter((color) => {
      if (!uniqueColors.has(color.Color)) {
        uniqueColors.add(color.Color);
        return true;
      }
      return false;
    });
  };

  const handleViewDetailsClick = () => {
    dispatch(setModalOpen(false));
    navigate(`/product/${product.Slug}`);
  };

  const handleClose = () => {
    dispatch(setModalOpen(false));
  };

  if (!isModalOpen || !product) {
    return null;
  }

  const uniqueSizes = getUniqueSizes(product.Colors);

  return (
    <>
      <div
        className={`product-modal fade ${isModalOpen ? "show" : ""}`}
        tabIndex="-1"
        style={{ display: isModalOpen ? "block" : "none" }}
      >
        <div
          className="product-modal-dialog modal-lg product-modal-dialog-centered"
          role="document"
        >
          <div className="product-modal-content">
            <div className="product-modal-header">
              <button
                type="button"
                className="btn-close"
                aria-label="Close"
                onClick={handleClose}
              ></button>
            </div>
            <form className="product-modal-view" onSubmit={handleSubmit}>
              <div className="product-modal-body">
                <div className="row">
                  <div className="col-md-6 d-flex">
                    <div className="product-carousel-container">
                      <VerticalCarousel
                        images={colorImages}
                        setMainImage={setMainImage}
                      />
                    </div>
                    <div className="product-modal-main-image-container">
                      <img
                        src={Image_URL + "products/thumbnails/" + mainImage}
                        alt={product.Title}
                        onError={(e) => {
                          e.target.src =
                            "https://jbadmin.alephaz.com/assets/img/placeholder-image.jpg";
                        }}
                        className="product-modal-main-image"
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="product-modal-details">
                      <h1>{product.Title}</h1>
                      <p className="product-modal-price">Rs {product.Price}</p>
                      <p className="product-modal-installment">
                        or 3 installments of Rs {Math.floor(product.Price / 3)}{" "}
                        with
                      </p>
                      <div className="product-modal-payment-logos-container">
                        <img
                          src={Mintpay}
                          alt="MintPay"
                          className="product-modal-payment-logo me-2"
                        />
                        <p className="product-modal-installment">or</p>
                        <img
                          src={Koko}
                          alt="Koko"
                          className="product-modal-payment-logo"
                        />
                      </div>
                      <p className="product-modal-description">
                        {product.Description}
                      </p>
                      <p
                        className="product-modal-view-details"
                        onClick={handleViewDetailsClick}
                      >
                        View Details
                      </p>
                      <p className="product-modal-size-label">Size:</p>
                      <div className="product-modal-size-options">
                        {uniqueSizes.map((size) => {
                          const sizeOption = product.Colors.find(
                            (color) => color.Size === size
                          );
                          const isOutOfStock =
                            sizeOption && parseInt(sizeOption.Qty) === 0;
                          return (
                            <button
                              type="button"
                              key={size}
                              onClick={() => handleSizeSelect(size)}
                              className={`product-modal-size-button ${
                                selectedSize === size ? "selected" : ""
                              }`}
                              disabled={isOutOfStock}
                              title={isOutOfStock ? "Out of stock" : ""}
                            >
                              {size}
                            </button>
                          );
                        })}
                      </div>
                      <p
                        className="product-modal-view-size-chart"
                        onClick={handleSizeChartClick}
                      >
                        {showSizeChart ? "Hide Size Chart" : "View Size Chart"}
                      </p>
                      {showSizeChart && (
                        <div className="product-modal-size-chart">
                          <img
                            src="https://www.glamly.com/cdn/shop/articles/MicrosoftTeams-image_46_1200x1200.png?v=1698044645"
                            alt="Size Chart"
                            className="product-modal-size-chart-image"
                          />
                        </div>
                      )}
                      <div className="product-modal-color-dots-container">
                        {getUniqueColors().map((color, index) => (
                          <div
                            key={index}
                            className={`product-modal-color-dot ${
                              selectedColor === color.Color ? "selected" : ""
                            }`}
                            style={{ backgroundColor: color.Color }}
                            title={color.Color}
                            onClick={() => handleColorClick(color)}
                          ></div>
                        ))}
                      </div>
                      <div className="product-modal-quantity-cart">
                        <div className="product-modal-quantity-counter">
                          <button
                            type="button"
                            className="product-modal-quantity-btn-left"
                            onClick={() =>
                              setQuantity(quantity > 1 ? quantity - 1 : 1)
                            }
                          >
                            <i className="fa-solid fa-minus"></i>
                          </button>
                          <input
                            type="number"
                            min="1"
                            value={quantity}
                            onChange={handleQuantityChange}
                            className="product-modal-quantity-field"
                          />
                          <button
                            type="button"
                            className="product-modal-quantity-btn"
                            onClick={() => setQuantity(quantity + 1)}
                          >
                            <i className="fa-solid fa-plus"></i>
                          </button>
                        </div>
                        <button
                          type="submit"
                          className="product-modal-add-to-cart"
                        >
                          <img
                            src={shoppingBagImage}
                            alt="shopping bag"
                            className="product-modal-shopping-bag-icon"
                          />{" "}
                          Add to Cart
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default ProductViewModal;
