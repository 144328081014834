import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './ResetPassword.css';
import Spinner from '../../common/loadingSpinnercomponent/Spinner';
import checkmarkIcon from '../../../assests/checkmark.svg';




const RESET_PASSWORD_URL = 'http://localhost/ecom-api/v1/user/auth/reset-password/';
const VALIDATE_TOKEN_URL = 'http://localhost/ecom-api/v1/user/auth/validate-password/';

const SuccessMessage = ({ onClose }) => (
    <div className="reset-password-success">
      <img 
        src={checkmarkIcon} 
        alt="Success" 
        className="reset-password-success-icon" 
      />
      <h2 className="reset-password-success-title">Password Reset Successful</h2>
      <p className="reset-password-success-description">Your password has been successfully reset.</p>
      <button onClick={onClose} className="reset-password-continue-button">
        Continue to Login
      </button>
    </div>
  );
  

const ResetPassword = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [token, setToken] = useState('');
    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState('');
    const [isTokenValid, setIsTokenValid] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const tokenFromUrl = searchParams.get('token');
        const emailFromUrl = searchParams.get('email');
        
        if (!tokenFromUrl || !emailFromUrl) {
            setError('Invalid reset link. Please request a new password reset.');
            toast.error('Invalid reset link. Please request a new password reset.');
            setIsLoading(false);
        } else {
            setToken(tokenFromUrl);
            setEmail(emailFromUrl);
            validateToken(tokenFromUrl, emailFromUrl);
        }
    }, [location]);

    const validateToken = async (token, email) => {
        try {
            const response = await fetch(VALIDATE_TOKEN_URL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ token, email }),
            });
            const data = await response.json();
            if (response.ok && data.status === 1) {
                setIsTokenValid(true);
            } else {
                throw new Error(data.message || 'Invalid or expired reset link');
            }
        } catch (error) {
            console.error('Error during token validation:', error);
            setError(error.message);
            toast.error(error.message || 'Invalid or expired reset link');
            if (error.debug) {
                console.log('Debug info:', error.debug);
            }
        } finally {
            setIsLoading(false);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        setError('');

        if (password !== confirmPassword) {
            setError('Passwords do not match');
            toast.error('Passwords do not match');
            setIsLoading(false);
            return;
        }

        try {
            const response = await fetch(RESET_PASSWORD_URL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ token, email, password }),
            });
            const data = await response.json();
            if (response.ok && data.status === 1) {
                console.log('Password reset successful!'); // Debug log
                setIsSuccess(true);
            } else {
                throw new Error(data.message || 'Failed to reset password');
            }
        } catch (error) {
            console.error('Error during reset password request:', error);
            setError(error.message);
            toast.error(error.message || 'Failed to reset password');
        } finally {
            setIsLoading(false);
        }
    };

    const handleSuccessClose = () => {
        navigate('/login');
    };

    const handleRetry = () => {
        navigate('/forgot-password');
    };

    if (isLoading) {
        return (
            <div className="reset-password-container">
                <div className="reset-password-card">
                    <Spinner />
                    <p className="reset-password-description">Validating reset link...</p>
                </div>
            </div>
        );
    }

    if (isSuccess) {
        return (
            <div className="reset-password-container">
                <div className="reset-password-card">
                    <SuccessMessage onClose={handleSuccessClose} />
                </div>
            </div>
        );
    }

    return (
        <div className="reset-password-container">
            <div className="reset-password-card">
                <h2 className="reset-password-title">
                    {error || !isTokenValid ? 'Error' : 'Reset Password'}
                </h2>
                {error || !isTokenValid ? (
                    <>
                        <p className="reset-password-description">{error}</p>
                        <button onClick={handleRetry} className="reset-password-continue-button">
                            Request New Password Reset
                        </button>
                    </>
                ) : (
                    <>
                        <p className="reset-password-description">
                            Please enter your new password below.
                        </p>
                        <form className="reset-password-form" onSubmit={handleSubmit}>
                            <label className="reset-password-label" htmlFor="password">
                                New Password
                            </label>
                            <input
                                type="password"
                                id="password"
                                className="reset-password-input"
                                placeholder="Enter new password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                            <label className="reset-password-label" htmlFor="confirmPassword">
                                Confirm New Password
                            </label>
                            <input
                                type="password"
                                id="confirmPassword"
                                className="reset-password-input"
                                placeholder="Confirm new password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                required
                            />
                            <button type="submit" className="reset-password-continue-button" disabled={isLoading}>
                                {isLoading ? <Spinner /> : 'Reset Password'}
                            </button>
                        </form>
                    </>
                )}
                <a href="/login" className="reset-password-back-to-login">
                    Back to login
                </a>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    );
};

export default ResetPassword;