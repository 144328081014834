import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { validateEmail } from '../../common/Validation';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  setEmail,
  setEmailError,
  setPasswordResetError,
  setPasswordResetSuccess,
} from '../../../slices/authSlice';
import './ForgotPassword.css'; 

const FORGOT_PASSWORD_URL = 'http://localhost/ecom-api/v1/user/auth/forgot-password/';

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { email, emailError, forgotPasswordError, forgotPasswordSuccess } = useSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    const emailValidationError = validateEmail(email);
    dispatch(setEmailError(emailValidationError));

    if (!emailValidationError) {
      try {
        const response = await fetch(FORGOT_PASSWORD_URL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email }),
        });

        const data = await response.json();

        if (response.ok) {
          if (data.status === 1) {
            dispatch(setPasswordResetSuccess(data.message));
            dispatch(setPasswordResetError(''));
            toast.success(data.message);
            setTimeout(() => navigate('/login'), 3000);
          } else {
            throw new Error(data.message || 'Password reset failed');
          }
        } else {
          throw new Error(data.message || `HTTP error! status: ${response.status}`);
        }
      } catch (error) {
        console.error('Error during forgot password request:', error);
        dispatch(setPasswordResetError(error.message || 'Something went wrong. Please try again.'));
        dispatch(setPasswordResetSuccess(''));
        toast.error(error.message || 'Something went wrong. Please try again.');
      } finally {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  };

  const handleEmailChange = (e) => {
    dispatch(setEmail(e.target.value));
    if (emailError) {
      dispatch(setEmailError(''));
    }
  };

  return (
    <div className="forgot-password-container">
      <div className="forgot-password-card">
        <h2>Forgot Password</h2>
        <p>Enter the email address associated with your account and we'll send a link to reset your password.</p>
        <form className="forgot-password-form" onSubmit={handleSubmit}>
          <label className="forgot-password-label" htmlFor="email">
            Email
          </label>
          <input
            type="email"
            id="email"
            className="forgot-password-input"
            placeholder="Enter your email"
            value={email}
            onChange={handleEmailChange}
            required
          />
          {emailError && <div className="error-message">{emailError}</div>}
          {forgotPasswordError && <div className="error-message">{forgotPasswordError}</div>}
          {forgotPasswordSuccess && <div className="success-message">{forgotPasswordSuccess}</div>}
          <button type="submit" className="forgot-password-continue-button" disabled={isLoading}>
            {isLoading ? 'Sending...' : 'Continue'}
          </button>
        </form>
        <a href="/login" className="forgot-password-back-to-login">
          Back to login
        </a>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default ForgotPassword;
