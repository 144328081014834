import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchOrderByInvoiceNumber } from '../../Services/profileServices';
import { Container, Row, Col, Card, Image } from 'react-bootstrap';
import './orderInformation.css';
import { Image_URL } from '../../config/config';

const OrderInformation = () => {
  const { invoiceNumber } = useParams();
  const [order, setOrder] = useState(null);

  useEffect(() => {
    const fetchOrder = async () => {
      console.log("Fetching order for Invoice Number:", invoiceNumber);
      const orderData = await fetchOrderByInvoiceNumber(invoiceNumber);
      console.log("Fetched Order Data:", orderData);
      setOrder(orderData[0]); // Assuming the API returns an array with one order
    };

    fetchOrder();
  }, [invoiceNumber]);

  if (!order) {
    return <div>Loading...</div>;
  }

  console.log("Order:", order);

  const billingDetails = order.BillingDetails && order.BillingDetails.length > 0 ? order.BillingDetails[0] : {};
  const shippingDetails = order.ShippingDetails && order.ShippingDetails.length > 0 ? order.ShippingDetails[0] : {};

  return (
    <Container className="my-5">
      <h2 className="mb-4">Order Information</h2>
      <Row>
        <Col md={6}>
          <Card className="mb-4">
            <Card.Body>
              <h5>Billing Information</h5>
              <p><strong>Name:</strong> {billingDetails.FirstName || "N/A"} {billingDetails.LastName || ""}</p>
              <p><strong>Email:</strong> {billingDetails.Email || "N/A"}</p>
              <p><strong>Contact Number:</strong> {billingDetails.Phone || "N/A"}</p>
              <p><strong>Address:</strong> {billingDetails.Address1 || "N/A"}, {billingDetails.Address2 || ""}, {billingDetails.City || ""}, {billingDetails.Country || ""}, {billingDetails.PostalCode || ""}</p>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card className="mb-4">
            <Card.Body>
              {order.OrderDetails && order.OrderDetails.length > 0 ? (
                order.OrderDetails.map((item) => (
                  <div key={item.Id} className="order-item">
                    <Row>
                      <Col xs={4}>
                        <Image src={`${Image_URL}${item.Sources}`} fluid />
                      </Col>
                      <Col xs={8}>
                        <h5>{item.Title || "N/A"}</h5>
                        <p><strong>SKU:</strong> #{item.ProductId || "N/A"}</p>
                        <p><strong>Variant:</strong> {item.Color || "N/A"}</p>
                        <p><strong>Qty:</strong> {item.Qty || "N/A"}</p>
                        <p><strong>Amount:</strong> Rs. {item.UnitPrice || "N/A"}</p>
                      </Col>
                    </Row>
                  </div>
                ))
              ) : (
                <p>No order details available.</p>
              )}
              <div className="order-summary">
                <Row>
                  <Col xs={6}>Subtotal</Col>
                  <Col xs={6} className="text-end">Rs. {order.OrderAmount}</Col>
                </Row>
                <Row>
                  <Col xs={6}>Discount</Col>
                  <Col xs={6} className="text-end">Rs. {order.Discount || 0}</Col>
                </Row>
                <Row>
                  <Col xs={6}>Shipping</Col>
                  <Col xs={6} className="text-end">Rs. {order.Shipping || 0}</Col>
                </Row>
                <Row className="fw-bold">
                  <Col xs={6}>Total</Col>
                  <Col xs={6} className="text-end">Rs. {order.TotalAmount || order.OrderAmount}</Col>
                </Row>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default OrderInformation;