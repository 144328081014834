import axios from 'axios';
import { API_BASE_URL } from '../config/config';

export const fetchReviewsByProductId = async (productId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/user/products/reviews/getbyproductid/?ProductId=${productId}`);
    console.log('Fetched reviews response:', response.data); // Log the entire response for debugging
    return response.data.data;
  } catch (error) {
    console.error('Error fetching reviews by product ID:', error);
    throw error;
  }
};
