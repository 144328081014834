// Importing axios and the required URL from the config file
import axios from 'axios';
import { GET_RETURNS_URL, GET_REASONS_URL } from '../config/config';

// Function to fetch all returns data
export const fetchReturns = async () => {
  try {
    const response = await axios.get(GET_RETURNS_URL);
    return response.data.data;
  } catch (error) {
    console.error('Error fetching returns data:', error);
    throw error;
  }
};

// Function to fetch all reasons for returns
export const fetchReasons = async () => {
  try {
    const response = await axios.get(GET_REASONS_URL);
    return response.data.data;
  } catch (error) {
    console.error('Error fetching reasons:', error);
    throw error;
  }
};



