import React from "react";
import { useDispatch, useSelector } from "react-redux";
import "./SignUp.css";
import signupImage from "../../../assests/auth pages.png";
import googleLogo from "../../../assests/google-logo.png";
import { Link } from "react-router-dom";
import {
  validateName,
  validateEmail,
  validatePassword,
} from "../../common/Validation";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { SIGNUP_URL } from "../../../config/config";
import {
  setFirstName,
  setLastName,
  setEmail,
  setPassword,
  setFirstNameError,
  setLastNameError,
  setEmailError,
  setPasswordError,
  setSignupError,
  setSignupSuccess,
} from "../../../slices/authSlice";

const SignUp = () => {
  const dispatch = useDispatch();
  const {
    firstName,
    lastName,
    email,
    password,
    firstNameError,
    lastNameError,
    emailError,
    passwordError,
  } = useSelector((state) => state.auth);

  const handleSubmit = async (event) => {
    event.preventDefault();

    let isValid = true;

    const firstNameValidation = validateName(firstName);
    if (firstNameValidation) {
      dispatch(setFirstNameError(firstNameValidation));
      isValid = false;
    } else {
      dispatch(setFirstNameError(""));
    }

    const lastNameValidation = validateName(lastName);
    if (lastNameValidation) {
      dispatch(setLastNameError(lastNameValidation));
      isValid = false;
    } else {
      dispatch(setLastNameError(""));
    }

    const emailValidation = validateEmail(email);
    if (emailValidation) {
      dispatch(setEmailError(emailValidation));
      isValid = false;
    } else {
      dispatch(setEmailError(""));
    }

    const passwordValidation = validatePassword(password);
    if (passwordValidation) {
      dispatch(setPasswordError(passwordValidation));
      isValid = false;
    } else {
      dispatch(setPasswordError(""));
    }

    if (isValid) {
      try {
        const response = await fetch(SIGNUP_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ firstName, lastName, email, password }),
        });

        if (!response.ok) {
          const errorData = await response.json();
          if (
            errorData.message &&
            errorData.message.includes("Email already exists")
          ) {
            dispatch(setSignupError("Sign-up failed. Please try again."));
            toast.error("Sign-up failed. Please try again.");
          } else {
            dispatch(setSignupError("Email already exists. Please use a different email."));
            toast.error("Email already exists. Please use a different email.");
          }
          return;
        }

        console.log("Sign-up successful!");
        dispatch(setSignupSuccess("User has been registered successfully."));
        toast.success("User has been registered successfully.");
      } catch (error) {
        console.error("Error during sign-up:", error);
        dispatch(setSignupError("Something went wrong. Please try again."));
        toast.error("Something went wrong. Please try again.");
      }
    }
  };

  return (
    <div className="signup-page">
      <div className="signup-grid">
        <div className="signup-image">
          <img src={signupImage} alt="SignUp Visual" />
        </div>
        <div className="signup-form-container">
          <div className="signup-form">
            <form onSubmit={handleSubmit}>
              <div className="styleName">Create an Account</div>
              <p>Enter your details to create an account</p>
              <label htmlFor="first-name">First Name</label>
              <input
                type="text"
                id="first-name"
                placeholder="Enter your first name"
                value={firstName}
                onChange={(e) => dispatch(setFirstName(e.target.value))}
                maxLength="100"
                required
              />
              {firstNameError && (
                <div className="error-message">{firstNameError}</div>
              )}
              <label htmlFor="last-name">Last Name</label>
              <input
                type="text"
                id="last-name"
                placeholder="Enter your last name"
                value={lastName}
                onChange={(e) => dispatch(setLastName(e.target.value))}
                maxLength="100"
                required
              />
              {lastNameError && (
                <div className="error-message">{lastNameError}</div>
              )}
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => dispatch(setEmail(e.target.value))}
                maxLength="100"
                required
              />
              {emailError && <div className="error-message">{emailError}</div>}
              <label htmlFor="password">Password</label>
              <input
                type="password"
                id="password"
                placeholder="Enter your password"
                value={password}
                onChange={(e) => dispatch(setPassword(e.target.value))}
                maxLength="20"
                required
              />
              {passwordError && (
                <div className="error-message">{passwordError}</div>
              )}
              <div className="forgot-password">
                <a href="/forgot-password">Forgot password</a>
              </div>
              <button type="submit" style={{ backgroundColor: "#EDA107" }}>
                Sign Up
              </button>
              <button type="button" className="google-signin">
                <img src={googleLogo} alt="Google Logo" /> Sign Up with Google
              </button>
              <div className="signin-link">
                Already have an account? <Link to="/login">Sign in</Link>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default SignUp;